import {Component, Input, OnChanges} from '@angular/core';
import {CommonModule} from "@angular/common";
import {CategoryStats, SessionSummary, SummaryStats} from "../../types/StatsCountByLang";

@Component({
  selector: 'app-resume-statistics-speaker',
  templateUrl: './resume-statistics-speaker.component.html',
  styleUrls: ['./resume-statistics-speaker.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class ResumeStatisticsSpeakerComponent implements OnChanges {
  @Input() statsForASpeaker : any
  @Input() loading : boolean = true;
  @Input() releve : any

  // summary: any = {
  //   nombreTexteParLangue: {} as CountByLanguage,
  //   nombreTraductionParLangue: {} as CountByLanguage,
  // }

  summary: SessionSummary = {
    nombreTexteParLangue: {},
    nombreTraductionParLangue: {},
  };

  ngOnChanges() {
    this.initializeSummary();
    this.calculateStats();
  }

  private initializeSummary() {
    this.summary.nombreTexteParLangue = {};
    this.summary.nombreTraductionParLangue = {};
  }

  private calculateStats() {
    this.statsForASpeaker.forEach((seance: any) => {
      const { modeSeance, langue, libelleCategorie, nbUniteFacturation, libelleModeFacturation } = seance;
      const unitCount = Number(nbUniteFacturation);

      if (modeSeance === 'VOCAL') {
        this.updateStats(this.summary.nombreTexteParLangue, langue, libelleCategorie, unitCount, libelleModeFacturation);
      } else if (modeSeance === 'TRADUCTION') {
        this.updateStats(this.summary.nombreTraductionParLangue, langue, libelleCategorie, unitCount, libelleModeFacturation);
      }
    });
  }

  private updateStats(stats: SummaryStats, langue: string, categorie: string, count: number, libelleModeFacturation: string) {
    // Initialiser la structure si elle n'existe pas
    if (!stats[langue]) {
      stats[langue] = {
        categories: {},
        total: 0
      };
    }
    if (!stats[langue].categories[categorie]) {
      stats[langue].categories[categorie] = {
        total: 0,
        sessions: 0,
        libelleModeFacturation
      };
    }

    // Mettre à jour les compteurs
    stats[langue].categories[categorie].total += count;
    stats[langue].categories[categorie].sessions += 1;
    stats[langue].total += count;
  }

  getLangCategories(language: string): { [key: string]: CategoryStats } {
    return this.summary.nombreTexteParLangue[language].categories;
  }

  hasVocal(): boolean {
    return !!this.summary.nombreTexteParLangue &&
      Object.keys(this.summary.nombreTexteParLangue).length > 0;
  }

  hasTranslations(): boolean {
    return !!this.summary.nombreTraductionParLangue &&
      Object.keys(this.summary.nombreTraductionParLangue).length > 0;
  }
}
