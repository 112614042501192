<h4 style="text-decoration: underline">Détail pour {{statsForASpeaker[0]?.libelleComedienne}}</h4>
<div class=" mt-4 mb-2">
  <div class="row justify-content-between mt-2">
    <div class="col-5">
      <h5>Infos pour les séances vocal :</h5>
      <table class="table mt-1 table-sm">
        <thead>
        <tr>
          <th scope="col">Langue</th>
          <th scope="col">Catégorie</th>
          <th scope="col">Quantitée</th>
        </tr>
        </thead>
        <tbody *ngIf="hasVocal()">
        <ng-container *ngFor="let langStat of summary.nombreTexteParLangue | keyvalue">
          <ng-container *ngFor="let catStat of getLangCategories(langStat.key) | keyvalue">
            <tr>
              <td>{{langStat.key}}</td>
              <td>{{catStat.key}}</td>
              <td>{{catStat.value.total}} {{catStat.value.libelleModeFacturation.toLowerCase()}}{{ catStat.value.total > 1 ? 's' : ''}}</td>
            </tr>
          </ng-container>
        </ng-container>
        </tbody>
        <tbody *ngIf="!hasVocal()">
        <tr>
          <td colspan="3">Aucune séance vocale trouvée sur ce mois.</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="col-5">
      <h5>Infos pour les séances de traduction :</h5>
      <table class="table mt-1 table-sm">
        <thead>
        <tr>
          <th scope="col">Langue</th>
          <th scope="col">Quantitée</th>
        </tr>
        </thead>
        <tbody *ngIf="hasTranslations()">
        <tr *ngFor="let stat of summary.nombreTraductionParLangue | keyvalue ">
          <td>{{stat.key}}</td>
          <td>{{stat.value}}</td>
        </tr>
        </tbody>
        <tbody *ngIf="!hasTranslations()">
        <tr>
          <td colspan="2">Aucune séance de traduction trouvée sur ce mois.</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row mt-2 align-items-end justify-content-center">
    <div class="m-2" *ngIf="releve?.messagesMobilite?.length > 0">
      <h5>
        Relevé d'activité pour la mobilité :
      </h5>
      <div class="col-5">
        <table class="table mt-1 table-sm">
          <thead>
            <tr>
              <th scope="col">Langue</th>
              <th scope="col">Nombre</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let stat of releve?.messagesMobilite ">
              <td>{{stat.LANGUE}}</td>
              <td>{{stat.NB_MESSAGES}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


