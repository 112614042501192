<div class="seanceslist">
  <table class="seanceslist__table table">
    <thead>
      <tr>
        <th>Comédien·ne</th>
        <th><i class="fa-solid fa-globe"></i></th>
        <th><i class="fa-solid fa-venus-mars"></i></th>
        <th>Nb. txt</th>
        <th>Envoyé le</th>
        <th>Type de séance</th>
        <th>Statut</th>
        <th>État</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="!this.seancesLoaded">
        <td class="text-center" colspan="9">
          <app-loader />
        </td>
      </tr>
      <ng-container *ngIf="this.seancesLoaded">
        <tr *ngIf="this.seances.length === 0">
          <td colspan="9" class="textslist__table__empty">Aucun résultat</td>
        </tr>
        <tr
          *ngFor="let seance of this.seances | slice: (this.page-1) * this.limit : this.page * this.limit ;"
          class="seanceslist__table__row" [ngClass]="{
              'seanceslist__table__row--canceled': seance.statut === this.seanceStatuses.CANCELED,
              'seanceslist__table__row--done': seance.statut === this.seanceStatuses.DONE,
            }"
        >
          <td>
            {{ this.getSpeakerDetails(seance.speak)?.prenom }}
            {{ this.getSpeakerDetails(seance.speak)?.nom }}
          </td>
          <td>
            {{ seance.langue }}
          </td>
          <td>
            {{
              this.getSpeakerDetails(seance.speak)?.genre === "002" ? "F" : "M"
            }}
          </td>
          <td>{{ seance.compteur_done}}/{{ seance.compteur_todo }}</td>
          <td>{{ seance.date_creation | date : "dd/MM/yyyy HH:mm" }}</td>
          <td>{{ seance.mode }}<i [ngClass]="{ 'fa-solid fa-microphone-lines ms-2' : seance.type === 'ON AIR'}"></i></td>
          <td>
            <i
              class="fa-solid"
              [ngClass]="{
                'fa-check': seance.statut === this.seanceStatuses.DONE,
                'fa-xmark': seance.statut === this.seanceStatuses.CANCELED,
                'fa-envelope-circle-check':
                  seance.statut === this.seanceStatuses.SENT,
                'fa-scissors':
                  seance.statut === this.seanceStatuses.AWAITING_CUTOUT ||
                  seance.statut === this.seanceStatuses.ONGOING_CUTOUT
              }"
              [ngbTooltip]="seance.statut"
            ></i>
            <i
              *ngIf="seance.statut === this.seanceStatuses.ONGOING_CUTOUT"
              class="fa-solid fa-hourglass-half ms-2"
            ></i>
          </td>
          <td>
            <i
              ngbTooltip="Non reçue"
              *ngIf="seance.compteur_done === 0"
              class="fa-solid fa-xmark"
            ></i>
            <i
              ngbTooltip="Partiellement reçue"
              *ngIf="
                seance.compteur_done > 0 &&
                seance.compteur_done < seance.compteur_todo
              "
              class="fa-solid fa-star-half-stroke"
            ></i>
            <i
              ngbTooltip="Reçue"
              *ngIf="seance.compteur_done === seance.compteur_todo"
              class="fa-solid fa-star"
            ></i>
          </td>
          <td class="text-end">
            <div class="d-flex justify-content-end">
              <ng-container
                *ngIf="
                  seance.statut !== this.seanceStatuses.CANCELED &&
                  seance.statut !== this.seanceStatuses.DONE
                "
              >
                <ng-container
                  *ngIf="
                  this.filterCriteria === this.seanceStatuses.AWAITING_CUTOUT || this.filterCriteria === this.seanceStatuses.ONGOING_CUTOUT">
                  <div class="input-group me-2" style="width: 240px">
                    <input
                      class="form-control"
                      type="text"
                      readonly
                      [value]="
                        seance.editeur
                          ? this.getEditorFullName(seance.editeur)
                          : ''
                      "
                    />
                    <button
                      class="input-group-text"
                      (click)="this.openAssignEditor(seance)"
                    >
                      <i class="fa-solid fa-pen"></i>
                    </button>
                  </div>
                </ng-container>

                <button
                  *ngIf="this.filterCriteria === 'ENVOYEE'"
                  class="btn btn-success me-2"
                  (click)="this.openDetails(seance.id, seance, seance.mode)"
                  ngbTooltip="{{seance.mode === 'VOCAL' ? 'Télécharger le fichier' : ''}}"
                >
                  <i class="fa-solid fa-download"></i>
                </button>
                <button
                  *ngIf="this.filterCriteria === 'EN COURS DE DECOUPE'"
                  class="btn btn-success me-2"
                  (click)="this.openDetails(seance.id, seance, seance.mode)"
                  ngbTooltip="{{seance.mode === 'VOCAL' ? 'Valider les textes' : ''}}"
                >
                  <i class="fa-solid fa-list-check"></i>
                </button>

                <ng-container *ngIf="this?.filterCriteria === 'MASTERING'">
                  <button
                    class="btn btn-success me-2"
                    (click)="this.validRemasterSeance(seance.id)"
                    ngbTooltip="Valider le master"
                  >
                    <i class="fa-solid fa-headphones-simple"></i>
                  </button>

                  <button
                    class="btn btn-secondary me-2"
                    (click)="this.openDetails(seance.id, seance, seance.mode)"
                    ngbTooltip="Rejeter des textes du master"
                  >
                    <i class="fa-solid fa-list-check"></i>
                  </button>
                </ng-container>


                <ng-container
                  *ngIf="
                    seance.statut === this.seanceStatuses.SENT &&
                    seance.mode === 'VOCAL'
                  "
                >
                  <button
                    class="btn btn-secondary me-2"
                    (click)="this.reassignSeance(seance.id)"
                    ngbTooltip="Réassigner la séance"
                  >
                    <i class="fa-solid fa-rotate-right"></i>
                  </button>
                  <button
                    *ngIf="seance.mode === 'VOCAL'"
                    class="btn btn-danger me-2"
                    (click)="this.cancelSeance(seance.id)"
                    ngbTooltip="Annuler la séance"
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </button>
                </ng-container>
                <ng-container
                  *ngIf="
                    seance.statut.includes(this.seanceStatuses.SENT) || seance.statut.includes(this.seanceStatuses.DONE) ||
                    seance.statut === this.seanceStatuses.ONGOING_CUTOUT ||
                    seance.statut === this.seanceStatuses.AWAITING_CUTOUT || seance.statut.includes(this.seanceStatuses.MASTERING)"
                >
                  <button
                    *ngIf="seance.mode === 'VOCAL'"
                    class="btn btn-primary"
                    (click)="
                      this.selectedSeance = seance;
                      this.getSeanceDetails(seance.id, true)
                    "
                    [disabled]="this.pdfLoading && seance.id === this.detailsId"
                    ngbTooltip="Télécharger la séance en PDF"
                  >
                    <i
                      class="fa-solid"
                      [ngClass]="{
                        'fa-file-pdf':
                          !this.pdfLoading || seance.id !== this.detailsId,
                        'fa-spinner':
                          this.pdfLoading && seance.id === this.detailsId,
                        'fa-spin':
                          this.pdfLoading && seance.id === this.detailsId
                      }"
                    ></i>
                  </button>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="seance.statut === this.seanceStatuses.DONE">
                <button
                  *ngIf="seance.mode === 'VOCAL'"
                  class="btn btn-primary"
                  (click)="
                      this.selectedSeance = seance;
                      this.getSeanceDetails(seance.id, true)
                    "
                  [disabled]="this.pdfLoading && seance.id === this.detailsId"
                  ngbTooltip="Télécharger la séance en PDF"
                >
                  <i
                    class="fa-solid"
                    [ngClass]="{
                        'fa-file-pdf':
                          !this.pdfLoading || seance.id !== this.detailsId,
                        'fa-spinner':
                          this.pdfLoading && seance.id === this.detailsId,
                        'fa-spin':
                          this.pdfLoading && seance.id === this.detailsId
                      }"
                  ></i>
                </button>
              </ng-container>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <div>
    <app-pagination
      *ngIf="this.seancesLoaded"
      [collectionSize]="this.collectionSize"
      [page]="this.page"
      [limit]="this.limit"
      (changePageEvent)="this.page = $event"
      (changeLimitEvent)="this.limit = $event"
    />
  </div>

  <app-dialog
    *ngIf="this.selectedSeance"
    title="Détails de la séance de {{ this.speakerService.getSpeakerFromCode(
            this.selectedSeance.speak,
            this.speakersList
          )?.prenom }} ({{this.selectedSeance.langue}}) du {{this.selectedSeance.date_creation | date:'dd/MM/yyy'}}"
    [visibility]="this.detailsDialogVisible"
    [customWidth]="1350"
    [largeContent]="true"
    (closeDialogEvent)="this.detailsDialogVisible = false"
  >
    <div class="container">
      <div class="row mb-3" *ngIf="filterCriteria === this.seanceStatuses.MASTERING">
        <div class="col-4">
          <button class="btn btn-danger" (click)="this.rejectMaster()">
            Rejeter les textes sélectionnés du master
          </button>
        </div>
      </div>
      <div
        *ngIf="
          this.filterCriteria ===this.seanceStatuses.SENT &&
          this.selectedSeance.mode === 'VOCAL'
        "
        class="row mb-3"
      >
        <div class="col-4">
          <div>
            <label class="form-label" for="seance-upload-file"
              >Fichier de la séance (Mp3, Wav, Zip, 7-zip - Max. 500Mo)</label
            >
            <input
              class="form-control"
              type="file"
              name="seance-upload-file"
              id="seance-upload-file"
              accept="audio/mpeg, audio/wav, application/zip, application/x-7z-compressed"
              (change)="this.getSeanceFile($event)"
            />
          </div>
        </div>
        <div class="col-7 offset-1">
          <div class="row">
            <div
              class="col-12 d-flex align-items-center justify-content-end"
            >
              <button
                class="btn btn-success"
                (click)="this.generateSeance(this.detailsId)"
                [disabled]="this.generateLoading || this.seanceToPost?.PRISES_VOIX?.length === 0
                // || this.isSeanceNoGenerate"
              >
                Intégrer la séance
              </button>
            </div>
          </div>
          <div *ngIf="this.generateLoading" class="row">
            <div class="col-12 mt-3">
              <ngb-progressbar
                class="mb-3"
                type="success"
                [value]="this.uploadProgress"
                [striped]="true"
                [showValue]="true"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          this.selectedSeance.mode === 'VOCAL' &&
          (this.filterCriteria === this.seanceStatuses.AWAITING_CUTOUT || this.filterCriteria === this.seanceStatuses.ONGOING_CUTOUT)
        "
        class="row mb-3"
      >
        <div class="col-12">

          <button class="btn btn-secondary ms-2 me-2" ngbTooltip="Copier le chemin de la séance dans le presse-papier."
                  [cdkCopyToClipboard]="this.selectedSeance.chemin_fichiers"><i class="fa-solid fa-copy pe-2"></i>
            Répertoire fichier sonore
          </button>
          <button class="btn btn-primary me-2" (click)="this.getPdf()" ngbTooltip="Télécharger la séance en PDF">
            <i class="fa-solid fa-file-pdf"></i>
          </button>
          <button class="btn btn-success me-2" (click)="this.validateSeance()">
            Valider les textes sélectionnés
          </button>
          <button class="btn btn-danger" (click)="this.ungenerateSeance()">
<!--                  [disabled]="this.isSeanceNoUngenerate" -->
            Rejeter les textes sélectionnés
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="textslist__table table">
            <thead>
              <tr>
                <th><input
                  type="checkbox" class="form-check-input" #selectAllCheckbox [checked]="true"
                  (change)="this.selectAllPV(selectAllCheckbox.checked)"/></th>
                <th>Code PV</th>
                <th>Client</th>
                <th>Date livraison</th>
                <th>Comédien·ne</th>
                <th><i class="fa-solid fa-globe"></i></th>
                <th><i class="fa-solid fa-venus-mars"></i></th>
                <th>Type message</th>
                <th>Guide</th>
                <th>Phase vocale</th>
                <th><i class="fa-solid fa-exclamation-circle"></i></th>
                <th><i class="fa-solid fa-language"></i></th>
                <th *ngIf="this.filterCriteria === 'ENVOYEE'"><i class="fa-solid fa-circle-xmark"></i></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="this.isLoading; else loaded">
                <td class="text-center" colspan="12">
                  <div class="textslist__table__loader">
                    <app-loader />
                  </div>
                </td>
              </tr>
              <tr *ngIf="this.details?.length === 0 && !this.isLoading">
                <td class="textslist__table__empty" colspan="12">
                  Aucun résultat
                </td>
              </tr>
              <ng-template #loaded>
                <tr *ngFor="let text of this.details; let i = index" [ngClass]="text.ZMS_PVDATERETOUR1">
                  <td>
                    <div class="form-check form-switch">
                      <input
                        #pvSwitch
                        class="form-check-input" name="selectPVForAction" type="checkbox" role="switch"
                        id="s-{{ i }}" [checked]="true" (change)="this.switchPVReception(text, this.pvSwitch.checked)"
                      />
                      <label class="form-check-label" for="s-{{ i }}"></label>
                    </div>
                  </td>
                  <td>{{ text.ZMS_PVCODE1 }}</td>
                  <td [ngbTooltip]="text.LIBELLE_CLIENT_FINAL" container="body">
                    {{ text.CLIENT_FINAL }} -
                    {{ text.LIBELLE_CLIENT_FINAL | truncate : 10 }}
                  </td>
                  <td>{{ text.ZMS_DATELIVRAISON | date : "dd/MM/YYYY" }}</td>
                  <td>
                    {{ text.PRENOM_COMEDIENNE }} {{ text.NOM_COMEDIENNE }}
                  </td>
                  <td>{{ this.getCodes(text.LANGUE, this.languesList) }}</td>
                  <td>{{ text.GENRE | truncate : 1 : "" }}</td>
                  <td>
                    {{
                      this.getLibelles(
                        text.ZMS_TYPEMESSAGE,
                        this.typesMessageList
                      )
                    }}
                  </td>
                  <td ngbTooltip="{{ text.ZMS_GUIDE }}" container="body">
                    {{ text.ZMS_GUIDE | truncate : 20 }}
                  </td>
                  <td>
                    {{
                      this.getLibelles(
                        text.ZMS_PHASEVOCALE,
                        this.phasesVocalesList
                      )
                    }}
                  </td>
                  <td>
                    <button
                      *ngIf="text.LER !== null"
                      [ngbTooltip]="text.LER"
                      container="body"
                      class="btn-invisible"
                    >
                      <i class="fa-solid fa-exclamation-circle"></i>
                    </button>
                  </td>
                  <td style="vertical-align: middle">
                    <div *ngIf="text.ZMS_PVTRADUCTION1 === 'X'">
                      <div
                        class="textslist__translationstatus"
                        [ngClass]="{
                          'textslist__translationstatus--sent':
                            text.ZMS_PVENVOITRAD1 !== null,
                          'textslist__translationstatus--done':
                            text.ZMS_PVRECEPTIONTRAD1 !== null
                        }"
                      ></div>
                    </div>
                  </td>
                  <td style="vertical-align: middle" *ngIf="text.ZMS_PVMOTIFREJET1 !== null">
                    <i class="fa-solid fa-circle-info" ngbTooltip="Moti rejet : {{text.ZMS_PVMOTIFREJET1}}"></i>
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </app-dialog>
</div>
