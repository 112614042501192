<div class="seancesongoing container page">
  <div class="row">
    <div class="col-12">
      <ng-container [formGroup]="this.filters">
        <div class="seanceslistfilters row">
          <div class="col-4">
            <app-speaker-selector
              [speakers]="this.speakers"
              [disabled]="!this.seancesLoaded"
              [showStatus]="false"
              (selectSpeakerEvent)="
                this.filters.controls['speak'].setValue($event.codespeak)
              "
              [resetListener]="this.resetFilterEvent"
            />
          </div>
          <div class="col-4">
            <div class="form-floating">
              <select
                name="mode"
                id="mode"
                class="form-select"
                formControlName="mode"
                (change)="this.resetIfNull('mode')"
              >
                <option [value]="null">Tous</option>
                <option value="VOCAL">Vocal</option>
                <option value="TRADUCTION">Traduction</option>
              </select>
              <label for="mode">Mode</label>
            </div>
          </div>
          <div class="col-2">
            <div class="datepicker form-floating">
              <input
                formControlName="dateCreationMin"
                type="text"
                class="form-control"
                id="dateCreationMin"
                name="dateCreationMin"
                placeholder=""
                ngbDatepicker
                #d="ngbDatepicker"
                (click)="d.toggle()"
              />
              <label for="dateCreationMin" class="form-label">Du</label>
            </div>
          </div>
          <div class="col-2">
            <div class="datepicker form-floating">
              <input
                formControlName="dateCreationMax"
                type="text"
                class="form-control"
                placeholder=""
                id="dateCreationMax"
                name="dateCreationMax"
                ngbDatepicker
                #e="ngbDatepicker"
                (click)="e.toggle()"
              />
              <label for="dateCreationMax" class="form-label">Au</label>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-3">
            <div class="form-floating">
              <select
                name="statut"
                id="statut"
                formControlName="statut"
                class="form-select"
                (change)="this.resetIfNull('statut')"
              >

                <option [value]="this.seanceStatuses.SENT">En attente PV</option>
                <option [value]="this.seanceStatuses.MASTERING">À masteriser</option>
                <option [value]="this.seanceStatuses.AWAITING_CUTOUT">À découper</option>
                <option [value]="this.seanceStatuses.ONGOING_CUTOUT">En cours de découpe</option>
                <option [value]="this.seanceStatuses.DONE">Intégrée</option>
                <option [value]="this.seanceStatuses.CANCELED">Annulée</option>
              </select>
              <label class="form-label" for="statut">Statut</label>
            </div>
          </div>
          <div class="col-2">
            <div class="form-floating">
              <select
                class="form-select"
                name="etat"
                id="etat"
                formControlName="etat"
                (change)="this.resetIfNull('etat')"
              >
                <option [value]="null">Tous</option>
                <option value="NON_RECU">Non reçue</option>
                <option value="PARTIEL">Reçue partiellement</option>
                <option value="COMPLET">Reçue entièrement</option>
              </select>
              <label class="form-label" for="etat">État</label>
            </div>
          </div>
          <div class="col-2" *ngIf="
                this.filters.controls['statut'].value ===
                this.seanceStatuses.ONGOING_CUTOUT
              ">
            <div class="form-floating">
              <select name="editeur" id="editeur" formControlName="editeur" class="form-select">
                <option value="" disabled selected>
                  Sélectionnez un éditeur
                </option>
                <option
                  *ngFor="let editor of this.editors"
                  [value]="editor.TRIGRAMME"
                >
                  {{ editor.NOM_EDITEUR }}
                </option>
              </select>
              <label for="editeur">Editeur</label>
            </div>
          </div>
          <div class="col-2">
            <div class="form-floating">
              <select
                class="form-select"
                name="categorie"
                id="categorie"
                formControlName="categorie"
                (change)="this.resetIfNull('categorie')"
              >
                <option [value]="null">Catégorie</option>
                <option
                  *ngFor="let cat of this.categoryPV"
                  value="{{ cat.cc_code }}"
                >
                  {{ cat.cc_libelle }}
                </option>
              </select>
              <label class="form-label" for="categorie">Catégorie</label>
            </div>
          </div>
          <div class="col-3 text-end">
            <button class="btn btn-primary me-2" (click)="this.search()">
              <i class="fa-solid fa-search"></i>
              Rechercher
            </button>
            <button class="btn btn-outline-primary" (click)="this.reset()">
              <i class="fa-solid fa-sync"></i>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <app-seances-list
        [seances]="this.seances"
        [generateLoading]="this.isPosting"
        [seancesLoaded]="this.seancesLoaded"
        [editors]="this.editors"
        [uploadProgress]="this.uploadCurrentSizeUploaded"
        [filterCriteria]="this.stateFilter"
        [collectionSize]="this.seances.length"
        (cancelEvent)="this.onCancelSeance($event)"
        (reassignEvent)="this.onReassignSeance($event)"
        (generateEvent)="this.onGenerateSeance($event)"
        (ungenerateEvent)="this.onUngenerateSeance($event)"
        (rejectTextMasterEvent)="this.onRejectMasteringText($event)"
        (validateEvent)="this.onValidateSeance($event)"
        (openSeanceDetailsEvent)="this.selectedSeance = $event"
        (openAssignEditorEvent)="this.onOpenAssignEditor($event)"
        (remasteredEvent)="this.onOpenRemaster($event)"
      />
    </div>
  </div>

  <app-dialog
    #cancelDialog
    title="Annuler la séance"
    [visibility]="this.cancelDialogVisible"
    (closeDialogEvent)="this.cancelDialogVisible = $event"
  >
    <app-post-states
      [isPosting]="this.isPosting"
      [isPostingOver]="this.isPostingOver"
      [postError]="this.postError"
      readyMessage="La séance {{
        this.seanceToPost
      }} sera annulée et les textes associés
      seront de nouveau disponibles à l'assignation"
      postingMessage="La séance {{
        this.seanceToPost
      }} est en cours d'annulation"
      completeMessage="La séance a été annulée avec succès"
      [postError]="this.postError"
    />
    <div dialog-footer>
      <div *ngIf="!this.isPosting && !this.isPostingOver && !this.postError">
        <button
          class="btn btn-danger me-2"
          (click)="this.cancelDialog.closeDialog()"
        >
          Fermer
        </button>
        <button class="btn btn-success" (click)="this.cancelSeance()">
          Valider
        </button>
      </div>
      <div *ngIf="this.isPostingOver">
        <button
          class="btn btn-success me-2"
          (click)="this.cancelDialog.closeDialog()"
        >
          Fermer
        </button>
      </div>
      <div *ngIf="this.postError">
        <button
          class="btn btn-danger me-2"
          (click)="this.cancelDialog.closeDialog()"
        >
          Fermer
        </button>
      </div>
    </div>
  </app-dialog>

  <app-dialog
    #reassignDialog
    title="Réassigner la séance"
    [visibility]="this.reassignDialogVisible"
    (closeDialogEvent)="this.reassignDialogVisible = $event"
  >
    <app-post-states
      [isPosting]="this.isPosting"
      [isPostingOver]="this.isPostingOver"
      [postError]="this.postError"
      readyMessage=""
      postingMessage="La séance est en cours de réassignation"
      completeMessage="La séance a été réassignée avec succès"
    />
    <!-- Remplace le readyMessage -->
    <div *ngIf="!this.isPosting && !this.isPostingOver && !this.postError">
      Réassigner la séance à :
      <div class="form-floating mt-2">
        <select
          #spk
          class="form-select"
          name=""
          id=""
          placeholder=""
          [(ngModel)]="this.newSpeaker"
        >
          <option value="" selected>Choix</option>
          <option
            *ngFor="let speaker of this.speakers"
            [value]="speaker.codespeak"
          >
            {{ speaker.prenom }} {{ speaker.nom }}
          </option>
        </select>
        <label for="">Comédien·ne</label>
      </div>
    </div>
    <div dialog-footer>
      <div *ngIf="!this.isPosting && !this.isPostingOver && !this.postError">
        <button
          class="btn btn-danger me-2"
          (click)="this.reassignDialog.closeDialog()"
        >
          Annuler
        </button>
        <button class="btn btn-success" (click)="this.reassignSeance()">
          Valider
        </button>
      </div>
      <div *ngIf="this.isPostingOver">
        <button
          class="btn btn-success me-2"
          (click)="this.reassignDialog.closeDialog()"
        >
          Fermer
        </button>
      </div>
      <div *ngIf="this.postError">
        <button
          class="btn btn-danger me-2"
          (click)="this.reassignDialog.closeDialog()"
        >
          Fermer
        </button>
      </div>
    </div>
  </app-dialog>

  <app-dialog
    #ungenerateDialog
    title="Dégénérer la séance"
    [visibility]="this.ungenerateDialogVisible"
    [largeContent]="true"
    (closeDialogEvent)="this.ungenerateDialogVisible = $event"
  >
    <app-post-states
      [isPosting]="this.isPosting"
      [isPostingOver]="this.isPostingOver"
      [postError]="this.postError"
      readyMessage="Les textes associés à cette séance seront de nouveau disponible pour
          l'enregistrement"
      postingMessage="La séance est en cours de dégénération"
      completeMessage="La séance a été dégénérée avec succès"
    />
    <div
      class="mt-2"
      *ngIf="!this.isPosting && !this.isPostingOver && !this.postError"
    >


      <div class="" *ngFor="let PVToReject of postBody.PRISES_VOIX; let i = index">
        <p class="fw-bold">{{PVToReject.ZMS_PVCODE1}}</p>
        <p class="fw-light fst-italic mb-2">{{PVToReject.ZMS_PVTEXTE}}</p>
        <div class="form-floating">
          <textarea
            class="form-control"
            name="ungenerate-reason"
            id="reason_rejected_{{i}}"
            [(ngModel)]="PVToReject.MOTIF_REJET"
            required
            placeholder="">
          </textarea>
            <label for="reason_rejected_{{i}}">Raison du rejet </label>
        </div>
        <hr>
      </div>

    </div>
    <div dialog-footer>
      <div *ngIf="!this.isPosting && !this.isPostingOver && !this.postError">
        <button
          class="btn btn-danger ms-2 me-4"
          (click)="this.ungenerateDialog.closeDialog()">
          Annuler
        </button>
        <button class="btn btn-success" (click)="this.ungenerateSeance()" [disabled]="!isAllReasonsFilled()">
          Valider
        </button>
      </div>
      <div *ngIf="this.isPostingOver">
        <button
          class="btn btn-success me-2"
          (click)="this.ungenerateDialog.closeDialog()"
        >
          Fermer
        </button>
      </div>
      <div *ngIf="this.postError">
        <button
          class="btn btn-danger me-2"
          (click)="this.ungenerateDialog.closeDialog()"
        >
          Fermer
        </button>
      </div>
    </div>
  </app-dialog>

  <app-dialog
    #validateDialog
    title="Valider la séance pour mixage"
    [visibility]="this.validateDialogVisible"
    (closeDialogEvent)="this.validateDialogVisible = $event"
  >
    <app-post-states
      [isPosting]="this.isPosting"
      [isPostingOver]="this.isPostingOver"
      [postError]="this.postError"
      readyMessage="La séance sera validée pour le mixage"
      postingMessage="La séance est en cours de validation"
      completeMessage="La séance a été validée avec succès"
    />
    <div dialog-footer>
      <div *ngIf="!this.isPosting && !this.isPostingOver && !this.postError">
        <button
          class="btn btn-danger me-2"
          (click)="this.validateDialog.closeDialog()"
        >
          Annuler
        </button>
        <button
          class="btn btn-primary ms-2 me-4"
          (click)="this.validateSeance(true)"
        >
          <i class="fa-solid fa-envelope-circle-check"></i>
          Valider et notifier par email
        </button>
        <button class="btn btn-success" (click)="this.validateSeance()">
          Valider
        </button>
      </div>
      <div *ngIf="this.isPostingOver">
        <button
          class="btn btn-success me-2"
          (click)="this.validateDialog.closeDialog()"
        >
          Fermer
        </button>
      </div>
      <div *ngIf="this.postError">
        <button
          class="btn btn-danger me-2"
          (click)="this.validateDialog.closeDialog()"
        >
          Fermer
        </button>
      </div>
    </div>
  </app-dialog>

  <app-dialog
    title="Valider le remastering pour cette seance"
    [visibility]="this.remasterValidDialogVisible"
    (closeDialogEvent)="this.remasterValidDialogVisible = false"
  >
    <app-post-states
      [isPosting]="this.isPosting"
      [isPostingOver]="this.isPostingOver"
      [postError]="this.postError"
      readyMessage="Souhaitez vous valider le master de cette séance ?"
      postingMessage="La séance est en cours de validation"
      completeMessage="La séance a été validé au master"
    />
    <div dialog-footer>
      <div *ngIf="!this.isPosting && !this.isPostingOver && !this.postError">
        <button
          class="btn btn-danger me-2"
          (click)="this.remasterValidDialogVisible = false"
        >
          Annuler
        </button>
        <button
          class="btn btn-success"
          (click)="
            this.remasterSeance()
          "
        >
          Valider
        </button>
      </div>
      <div *ngIf="this.isPostingOver">
        <button class="btn btn-success me-2" (click)="this.remasterValidDialogVisible = false">
          Fermer
        </button>
      </div>
      <div *ngIf="this.postError">
        <button class="btn btn-danger me-2" (click)="this.remasterValidDialogVisible = false">
          Fermer
        </button>
      </div>
    </div>
  </app-dialog>

  <app-dialog
    title="Rejeter des textes du remastering"
    [visibility]="this.remasterRejectDialogVisible"
    (closeDialogEvent)="this.remasterRejectDialogVisible = false"
  >
    <app-post-states
      [isPosting]="this.isPosting"
      [isPostingOver]="this.isPostingOver"
      [postError]="this.postError"
      readyMessage="Indiquer la raison du rejet de remestering : "
      postingMessage="Les textes sont en cours de rejet"
      completeMessage="Les textes ont été rejetés"
    />

    <div class="mt-2" *ngIf="!this.isPosting && !this.isPostingOver && !this.postError">
      <div class="" *ngFor="let PVToReject of postBody.PRISES_VOIX; let i = index">
        <p class="fw-bold">{{PVToReject.ZMS_PVCODE1}}</p>
        <p class="fw-light fst-italic mb-2">{{PVToReject.ZMS_PVTEXTE}}</p>
        <div class="form-floating">
            <textarea
              class="form-control"
              name="ungenerate-reason"
              id="reason_rejected_{{i}}"
              [(ngModel)]="PVToReject.MOTIF_REJET"
              required
              placeholder="">
            </textarea>
          <label for="reason_rejected_{{i}}">Raison du rejet </label>
        </div>
        <hr>
      </div>
    </div>
    <div dialog-footer>
      <div *ngIf="!this.isPosting && !this.isPostingOver && !this.postError">
        <button
          class="btn btn-danger me-2"
          (click)="this.remasterRejectDialogVisible = false"
        >
          Annuler
        </button>
        <button class="btn btn-success" (click)="this.ungenerateSeance()" [disabled]="!isAllReasonsFilled()">Valider</button>
      </div>
      <div *ngIf="this.isPostingOver">
        <button class="btn btn-success me-2" (click)="this.remasterRejectDialogVisible = false">
          Fermer
        </button>
      </div>
      <div *ngIf="this.postError">
        <button class="btn btn-danger me-2" (click)="this.remasterRejectDialogVisible = false">
          Fermer
        </button>
      </div>
    </div>
  </app-dialog>

  <app-dialog
    *ngIf="this.selectedSeance"
    title="Attribuer un éditeur pour la découpe"
    [visibility]="this.editorDialogVisible"
    (closeDialogEvent)="this.editorDialogVisible = false"
  >
    <app-post-states
      [isPosting]="this.isPosting"
      [isPostingOver]="this.isPostingOver"
      [postError]="this.postError"
      postingMessage="L'éditeur est en cours d'attribution"
      completeMessage="L'éditeur a été attribué à la séance"
    />
    <div *ngIf="!this.isPosting && !this.isPostingOver && !this.postError">
      <!-- Overides readyMessage state -->
      <div class="form-floating">
        <select
          [(ngModel)]="this.selectedEditor"
          class="form-select mb-3"
          id=""
        >
          <option value="" disabled selected>Sélectionnez un éditeur</option>
          <option
            *ngFor="let editor of this.editors"
            [value]="editor.TRIGRAMME"
            [selected]="this.selectedSeance.editeur === editor.TRIGRAMME"
          >
            {{ editor.NOM_EDITEUR }}
          </option>
        </select>
        <label for="">Éditeur</label>
      </div>
    </div>

    <div dialog-footer>
      <div *ngIf="!this.isPosting && !this.isPostingOver && !this.postError">
        <button
          class="btn btn-danger me-2"
          (click)="this.editorDialogVisible = false"
        >
          Annuler
        </button>
        <button
          class="btn btn-success"
          [disabled]="this.selectedEditor === ''"
          (click)="
            this.assignEditor(this.selectedEditor, this.selectedSeance.id)
          "
        >
          Valider
        </button>
      </div>
      <div *ngIf="this.isPostingOver">
        <button
          class="btn btn-success me-2"
          (click)="this.editorDialogVisible = false"
        >
          Fermer
        </button>
      </div>
      <div *ngIf="this.postError">
        <button
          class="btn btn-danger me-2"
          (click)="this.editorDialogVisible = false"
        >
          Fermer
        </button>
      </div>
    </div>
  </app-dialog>
</div>
