import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtsappService } from '@common/services/atsapp.service';
import { Subject, takeUntil } from 'rxjs';
import { PlanningEvent } from '../../../types/planning-event';
import { ToastService } from '../../../services/toast.service';
import {
  format,
  isBefore,
  isToday,
  isWithinInterval,
  parseISO,
  startOfToday,
} from 'date-fns';
import { PlanningEventReasons } from '../../../types/planning-event-reasons.enum';
import { enUS } from 'date-fns/locale';
import { Speaker } from '../../../types/speaker';
import { Seance } from '../../../types/seance';
import { SeanceStatuses } from '../../../types/seanceStatuses.enum';

@Component({
  selector: 'app-admin-dashboard',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent implements OnInit, OnDestroy {
  constructor(
    private atsappService: AtsappService,
    private toastService: ToastService
  ) {}

  sickLeave: number = 0;
  leave: number = 0;
  available: number = 0;
  unavailable: number = 0;
  onSite: number = 0;
  defaultAvailable: number = 0;
  destroy$: Subject<boolean> = new Subject<boolean>();

  pvStats: any;
  seancesTranslation: any;
  seancesPV: any;

  onSiteDetails: any[] = [];

  format = format;
  parseISO = parseISO;

  ngOnInit(): void {
    this.atsappService
      .getSpeakers(false)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          const today = format(startOfToday(), 'EEE', { locale: enUS });

          res.forEach((spk: Speaker) => {
            if (spk.disponibilite && spk.disponibilite.includes(today)) {
              this.defaultAvailable += 1;
            }
          });
        },
        error: (error) => {
          this.toastService.show(
            'Le chargement des comédien·ne·s à échoué pour la raison suivante : ' +
              error.error.message,
            'danger'
          );
        },
      });
    this.atsappService
      .getAllPlanningEvents()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          const today = format(startOfToday(), 'EEE', { locale: enUS });

          for (let event of res) {
            if (
              isWithinInterval(startOfToday(), {
                start: new Date(event.date_debut).setHours(0, 0),
                end: new Date(event.date_fin).setHours(0, 0),
              })
            ) {
              if (event.comedienne_id.disponibilite?.includes(today)) {
                this.defaultAvailable -= 1;
              }

              switch (event.raison) {
                case PlanningEventReasons.SICK_LEAVE:
                  this.sickLeave += 1;
                  break;
                case PlanningEventReasons.LEAVE:
                  this.leave += 1;
                  break;
                case PlanningEventReasons.ON_SITE:
                  this.onSite += 1;
                  this.onSiteDetails.push({hour: event.date_debut, speaker: `${event.comedienne_id.prenom} ${event.comedienne_id.nom}`})

                  break;
                case PlanningEventReasons.NO_SEANCE:
                case PlanningEventReasons.PUBLIC_HOLIDAY:
                  this.unavailable += 1;
                  break;
                case PlanningEventReasons.SEANCE:
                  this.available += 1;
                  break;
              }
            }
          }
        },
        error: (error) => {
          this.toastService.show(
            'Le chargement des événements à échoué pour la raison suivante : ' +
              error.error.message,
            'danger'
          );
        },
      });

    this.atsappService
      .getGlobalStats()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          this.pvStats = res.EtatTextes;
          this.seancesPV = res.EtatSeances.PV;
          this.seancesTranslation = res.EtatSeances.TRAD;
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  protected readonly Object = Object;
}
