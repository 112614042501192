<div class="textstosend__filters" [formGroup]="this.filterForm">
  <div class="row">
    <div class="col-4">
      <app-speaker-selector
        [speakers]="this.speakersList"
        (selectSpeakerEvent)="this.selectSpeaker($event.codespeak)"
        [disabled]="this.isLoading"
        [resetListener]="this.resetFiltersEvent"
        [showStatus]="this.showStatusComedienne"
        [label]="this.taxonomy === this.textsListTaxonomy.TRANSLATOR ? 'Traducteur·rice' : 'Comédien·ne'"
      />
    </div>
    <div class="col-3">
      <div class="form-floating">
        <select
          #lng
          name="lang"
          id="lang"
          class="form-select"
          formControlName="ZMS_LANGUE"
        >
          <option value="" selected>Choix</option>
          <option
            *ngFor="let lng of this.languesList"
            value="{{ lng.cc_code }}"
          >
            {{ lng.cc_libelle }}
          </option>
        </select>
        <label for="lang" class="form-label">Langue</label>
      </div>
    </div>
    <div *ngIf="!this.hideFilters.includes('genre')" class="col-2">
      <div
        class="d-flex justify-content-between align-items-center h-100"
      >
        <div class="form-check">
          <input
            #gender_fem
            type="radio"
            class="form-check-input"
            name="ZMS_PVGENRE1"
            id="gender-fem"
            formControlName="ZMS_PVGENRE1"
            value="Féminin"
          />
          <label for="gender-fem"> Féminin </label>
        </div>
        <div class="form-check">
          <input
            #gender_masc
            type="radio"
            class="form-check-input"
            name="ZMS_PVGENRE1"
            id="gender-masc"
            formControlName="ZMS_PVGENRE1"
            value="Masculin"
          />
          <label for="gender-masc">Masculin</label>
        </div>
      </div>
    </div>

    <div class="col-3 text-end">
      <button
        style="width: 42px"
        class="btn btn-outline-secondary"
        (click)="this.moreFiltersCollapsed = !this.moreFiltersCollapsed"
        [attr.aria-expanded]="!this.moreFiltersCollapsed"
        aria-controls="moreFiltersCollapse"
      >
        {{ this.moreFiltersCollapsed ? "+" : "-" }}
      </button>
      <button
        class="btn btn-outline-secondary mx-3"
        (click)="this.resetFilters()"
      >
        <i class="fa-solid fa-sync"></i>
      </button>
      <button class="btn btn-primary" (click)="this.searchEvent()" [disabled]="this.isLoading">
        <i class="fa-icon fa-solid fa-search"></i>
        Rechercher
      </button>
    </div>
  </div>
  <div
    class="row"
    [ngClass]="{
      'mt-3': this.moreFiltersCollapsed,
      'my-3': !this.moreFiltersCollapsed
    }"
  >
    <div class="col-12">
      <div #collapse="ngbCollapse" [(ngbCollapse)]="this.moreFiltersCollapsed">
        <div class="row">
          <div class="col-2">
            <div class="datepicker form-floating">
              <input
                type="text"
                class="form-control"
                placeholder=""
                ngbDatepicker
                #d="ngbDatepicker"
                formControlName="ZMS_DATELIVRAISON"
                (click)="d.toggle()"
              />
              <label for="date-pv" class="form-label">Date livraison</label>
            </div>
          </div>
          <div class="col-2" *ngIf="!this.hideFilters.includes('ZMS_DATEPVDIST1')">
            <div class="datepicker form-floating">
              <input
                type="text"
                class="form-control"
                placeholder=""
                ngbDatepicker
                #e="ngbDatepicker"
                formControlName="ZMS_DATEPVDIST1"
                (click)="e.toggle()"
              />
              <label class="form-label">Date attribution</label>
            </div>
          </div>
          <div class="col-2">
            <div class="form-floating">
              <select
                #zcg
                name="categorie"
                id="categorie"
                class="form-select"
                formControlName="ZMS_CATEGORIE"
              >
                <option value="" selected>Catégorie</option>
                <option
                  *ngFor="let cat of this.categoryPV"
                  value="{{ cat.cc_code }}"
                >
                  {{ cat.cc_libelle }}
                </option>
              </select>
              <label for="lang" class="form-label">Catégorie</label>
            </div>
          </div>
          <div class="col-3">
            <div class="form-floating">
              <select
                #phv
                name="phase-vocale"
                id="phase-vocale"
                class="form-select"
                formControlName="ZMS_PHASEVOCALE"
              >
                <option selected value="">Choix</option>
                <option
                  *ngFor="let phv of this.phasesVocalesList"
                  value="{{ phv.cc_code }}"
                >
                  {{ phv.cc_libelle }}
                </option>
              </select>
              <label for="phase-vocale" class="form-label">Phase vocale</label>
            </div>
          </div>
          <div class="col-3" *ngIf="typesMessageList !== null">
              <angular2-multiselect [data]="typesMessageList" [settings]="settingListeTypeMessage"
                                    formControlName="ZMS_TYPEMESSAGE"
              ></angular2-multiselect>
          </div>
          <div class="col-2">
            <div class="form-floating">
              <input
                #tiers
                type="text"
                name="code-client"
                id="code-client"
                class="form-control"
                placeholder=""
                formControlName="TIERS"
              />
              <label for="code-client">Code client</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
