import { Component, OnInit } from '@angular/core';
import {CommonModule} from '@angular/common';
import { WeeklyPlanningPreviewComponent } from 'projects/speaker-platform/src/app/molecules/weekly-planning-preview/weekly-planning-preview.component';
import { AtsappService } from '@common/services/atsapp.service';
import { UserService } from '@common/services/user.service';
import {
  areIntervalsOverlapping,
  eachDayOfInterval,
  endOfWeek,
  format,
  isSameMonth,
  startOfWeek,
} from 'date-fns';
import { PlanningEvent } from '../../types/planning-event';
import { Seance } from '../../types/seance';
import { Router } from '@angular/router';
import { SeanceStatuses } from '../../types/seanceStatuses.enum';
import { PriseVoix } from '../../types/prisesVoix';
import { FormsModule } from "@angular/forms";
import {ToastService} from "../../services/toast.service";

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CommonModule, WeeklyPlanningPreviewComponent, FormsModule],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor(
    private atsappService: AtsappService,
    protected userService: UserService,
    private router: Router,
    private modalService : ToastService
  ) {}

  weekStartDate: Date = new Date();
  weekEndDate: Date = new Date();
  today: string = '';
  dateStartStatus: string = '';
  dateEndStatus: string = '';
  statut : string = '';
  weekInterval!: Interval;
  weekStarsAndEndsInSameMonth: boolean = false;
  eventsInWeek: PlanningEvent[] = [];
  ongoingSeances: Seance[] = [];
  now: Date = new Date();
  speak : any

  seanceStatuses = SeanceStatuses;

  fetchEventsComplete: boolean = false;
  format = format;
  eachDayOfInterval = eachDayOfInterval;

  pastReleveValidated: boolean = true;

  ngOnInit(): void {
    this.now = new Date();
    this.weekStartDate = startOfWeek(new Date(), { weekStartsOn: 1 });
    this.weekEndDate = endOfWeek(new Date(), { weekStartsOn: 1 });
    this.weekStarsAndEndsInSameMonth = isSameMonth(
      this.weekStartDate,
      this.weekEndDate
    );

    if (this.userService.userData) {
      if (!this.userService.userData.isAdmin) {
        this.atsappService.getSpeakerById(this.userService.userData.speakerId).subscribe({
          next: data => {
            this.speak = data;
          }
        })
      }
    }

    this.weekInterval = { start: this.weekStartDate, end: this.weekEndDate };
    if (this.userService.userData) {
      if (this.userService.userData.isAdmin) {
        this.router.navigate(['/admin/dashboard']);
        return;
      }

      this.userService.userDataLoaded.subscribe((loaded: boolean) => {
        this.initDashboard();
        return;
      });

      if (this.userService.userData.speakerId !== -1) {
        this.initDashboard();
      }
    }
    this.checkLastReleveSignature()
  }

  initDashboard() {
    this.atsappService
      .getPlanningEventsByComedienne(this.userService.userData.speakerId)
      .subscribe({
        next: (res: any) => {
          res.forEach((event: PlanningEvent) => {
            const eventInterval: Interval = {
              start: new Date(event.date_debut).setHours(0, 0),
              end: new Date(event.date_fin).setHours(0, 0),
            };
            areIntervalsOverlapping(eventInterval, this.weekInterval, {
              inclusive: true,
            }) && this.eventsInWeek.push(event);
          });
        },
        complete: () => (this.fetchEventsComplete = true),
      });

    this.atsappService
      .getSeanceBySpeaker(this.userService.userData.speakerId)
      .subscribe({
        next: (res: any) => {
          for (let seance of res) {
            if (seance.statut === this.seanceStatuses.SENT && seance.mode === 'VOCAL') {
              this.getStandbyCount(seance);
            }
          }
        },
      });
  }

  getStandbyCount(seance: Seance) {
    const _seance = seance;
    this.atsappService.getPvsBySeance(seance.id).subscribe({
      next: (res: any) => {
        _seance._stdby = res.seancePVs.filter(
          (s: PriseVoix) => s.ZMS_STDBYPV1 === 'X'
        ).length;
        this.ongoingSeances.push(seance);
        this.ongoingSeances.sort((a, b) => a.id - b.id);
      },
    });
  }

  statutDisplay () {
    const now = new Date();
    return now <= new Date(this.speak?.date_fin_statut) && now >= new Date(this.speak?.date_debut_statut);
  }

  submitStatus(): void {
    const formData = new FormData();


    formData.append('statut', this.statut)
    formData.append('dateDebutStatut',this.dateStartStatus + ' 00:00:00');
    formData.append('dateFinStatut', this.dateEndStatus + ' 23:59:59');

    this.atsappService.patchSpeaker(formData, this.userService.userData.speakerId).subscribe({
      next: () => {
        this.atsappService.getSpeakerById(this.userService.userData.speakerId).subscribe({
          next: data => {
            this.speak = data;
          }
        })
      },
      complete: () => {
        this.statut = '';
        this.dateStartStatus = '';
        this.dateEndStatus = '';
        this.modalService.show('Statut enregistré.', "success")
      },
    })
  }

  checkLastReleveSignature() {
    const dateTmp = new Date();
    dateTmp.setMonth(dateTmp.getMonth() -1);
    const body = dateTmp.getFullYear() + '-' + (dateTmp.getMonth() + 1)

    this.atsappService.getReleveForSpeakBySpk(body).subscribe({
      next: (res: any) => {
        if (res.length > 0 && res[0].dateValidationComedienne === null) {
            this.pastReleveValidated = false;
        }
      }
    })
  }
}
