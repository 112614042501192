import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../config/constants';
import { ParseFiltersService } from './helpers/parse-filters.service';
import { FiltersProps } from '../types/filters';
import {AbstractService} from "@common/services/abstract.service";
import {ToastService} from "../../speaker-platform/src/app/services/toast.service";
import {Router} from "@angular/router";
import {catchError} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class AtsappService extends AbstractService{
  constructor(
    protected http: HttpClient,
    protected constants: Constants,
    protected parseFilters: ParseFiltersService,
    toastService: ToastService,
    router : Router
  ) {
    super(toastService, router );}

  getPVsToAssign(filters: FiltersProps[], page: number, limit: number) {
    return this.http.get(
      `${
        this.constants.ATSAPP_API_URL
      }/comedienne/getPVsToAssign?page=${page}&limit=${limit}&${this.parseFilters.stringifyFilters(
        filters
      )}`
    ).pipe(catchError(err => this.handleError(err)));
  }

  getAssignedPVs(filters: FiltersProps[], page: number, limit: number) {
    return this.http.get(
      `${
        this.constants.ATSAPP_API_URL
      }/comedienne/getAssignedPVs/all?page=${page}&limit=${limit}&${this.parseFilters.stringifyFilters(
        filters
      )}`
    ).pipe(catchError(err => this.handleError(err)));
  }

  getAssignedPVsBySpeaker(
    filters: FiltersProps[],
    speaker: string,
    page: number,
    limit: number
  ) {
    return this.http.get(
      `${this.constants.ATSAPP_API_URL}/comedienne/getAssignedPVs/${speaker}?page=${page}&limit=${limit}`
    ).pipe(catchError(err => this.handleError(err)));
  }

  getStandbyPVs(filters: FiltersProps[], page: number, limit: number) {
    return this.http.get(
      `${
        this.constants.ATSAPP_API_URL
      }/comedienne/getStandbyPVs/all?page=${page}&limit=${limit}&${this.parseFilters.stringifyFilters(
        filters
      )}`
    ).pipe(catchError(err => this.handleError(err)));
  }

  getTextToTranslate(filters: FiltersProps[], page: number, limit: number) {
    return this.http.get(
      `${
        this.constants.ATSAPP_API_URL
      }/comedienne/getTextToTranslate?page=${page}&limit=${limit}&${this.parseFilters.stringifyFilters(
        filters
      )}`
    ).pipe(catchError(err => this.handleError(err)));
  }

  getSentTextsToTranslate(
    filters: FiltersProps[],
    page: number,
    limit: number
  ) {
    return this.http.get(
      `${
        this.constants.ATSAPP_API_URL
      }/comedienne/getSentTextsToTranslate/all?page=${page}&limit=${limit}&${this.parseFilters.stringifyFilters(
        filters
      )}`
    ).pipe(catchError(err => this.handleError(err)));
  }

  getStandbyTranslations(filters: FiltersProps[], page: number, limit: number) {
    return this.http.get(
      `${
        this.constants.ATSAPP_API_URL
      }/comedienne/getStandbyTranslations/all?page=${page}&limit=${limit}&${this.parseFilters.stringifyFilters(
        filters
      )}`
    ).pipe(catchError(err => this.handleError(err)));
  }

  /**
   *
   * @param all set to true to get active and inactive speakers | false to get only active
   * @returns Speaker[]
   */
  getSpeakers(all: boolean) {
    return this.http.get(
      `${this.constants.ATSAPP_API_URL}/comedienne/getComediennes/${
        all ? 0 : 1
      }`
    ).pipe(catchError(err => this.handleError(err)));
  }

  checkSeance(body: {comedienne: string, langue: string, dateEstimation : string}) {
    return this.http.post(
      `${this.constants.ATSAPP_API_URL}/comedienne/prisevoix/checkseances`,
      body
    ).pipe(catchError(err => this.handleError(err)));
  }

  assignPVToSpeaker(body: any) {
    return this.http.post(
      `${this.constants.ATSAPP_API_URL}/comedienne/prisevoix/assignToComedienne`,
      body
    ).pipe(catchError(err => this.handleError(err)));
  }

  unassignPVs(body: any) {
    return this.http.post(
      `${this.constants.ATSAPP_API_URL}/comedienne/prisevoix/unassignPVs`,
      body
    ).pipe(catchError(err => this.handleError(err)));
  }

  standbyPV(body: any) {
    return this.http.post(
      `${this.constants.ATSAPP_API_URL}/comedienne/prisevoix/standby`,
      body
    ).pipe(catchError(err => this.handleError(err)));
  }

  assignTranslationsToSpeaker(body: any) {
    return this.http.post(
      `${this.constants.ATSAPP_API_URL}/comedienne/translations/assignTranslations`,
      body
    ).pipe(catchError(err => this.handleError(err)));
  }

  unassignTranslations(body: any) {
    return this.http.post(
      `${this.constants.ATSAPP_API_URL}/comedienne/translations/unassignTranslations`,
      body
    ).pipe(catchError(err => this.handleError(err)));
  }

  sendTranslatedText(body: any) {
    return this.http.post(
      `${this.constants.ATSAPP_API_URL}/comedienne/translations/sendTranslatedText`,
      body
    ).pipe(catchError(err => this.handleError(err)));
  }

  // Séances

  getAllSeances(body: any) {
    return this.http.post(
      `${this.constants.ATSAPP_API_URL}/comedienne/seances/getAll`,
      body
    ).pipe(catchError(err => this.handleError(err)));
  }

  getSeanceBySpeaker(speakerId: number) {
    return this.http.get(
      `${this.constants.ATSAPP_API_URL}/comedienne/seances/getByComedienne/${speakerId}`
    ).pipe(catchError(err => this.handleError(err)));
  }

  getPvsBySeance(id: number) {
    return this.http.get(
      `${this.constants.ATSAPP_API_URL}/comedienne/getPVsBySeance/${id}`
    ).pipe(catchError(err => this.handleError(err)));
  }
  getStandByBySeance(idSeance: number) {
    return this.http.get(
      `${this.constants.ATSAPP_API_URL}/comedienne/seance/standby/${idSeance}`
    ).pipe(catchError(err => this.handleError(err)));
  }

  getTranslationsBySeance(id: number) {
    return this.http.get(
      `${this.constants.ATSAPP_API_URL}/comedienne/getTranslationsBySeance/${id}`
    ).pipe(catchError(err => this.handleError(err)));
  }

  generatePV(body: any, seanceId: number | undefined) {
    return this.http.post(
      `${this.constants.ATSAPP_API_URL}/comedienne/prisevoix/generatePV/${seanceId}`,
      body, {reportProgress: true, observe: 'events'}
    ).pipe(catchError(err => this.handleError(err)));
  }

  ungeneratePV(body: any, seanceId: number) {
    return this.http.post(
      `${this.constants.ATSAPP_API_URL}/comedienne/prisevoix/ungeneratePVs/${seanceId}`,
      body
    ).pipe(catchError(err => this.handleError(err)));
  }

  validatePV(body: any, seanceId: number) {
    return this.http.post(
      `${this.constants.ATSAPP_API_URL}/comedienne/prisevoix/validatePVs/${seanceId}`,
      body
    ).pipe(catchError(err => this.handleError(err)));
  }

  cancelSeance(id: number) {
    return this.http.post(
      `${this.constants.ATSAPP_API_URL}/comedienne/seances/cancel/${id}`,
      ''
    ).pipe(catchError(err => this.handleError(err)));
  }

  reassignSeance(id: number, codespeak: string) {
    return this.http.post(
      `${this.constants.ATSAPP_API_URL}/comedienne/seances/reassign/${id}/${codespeak}`,
      ''
    ).pipe(catchError(err => this.handleError(err)));
  }

  // Tablettes
  getChoixCod(cc_type: string) {
    return this.http.get(
      `${this.constants.ATSAPP_API_URL}/tablettes/choixcod/${cc_type}`
    ).pipe(catchError(err => this.handleError(err)));
  }

  // Planning

  getPlanningEventsByComedienne(id: number) {
    return this.http.get(
      `${this.constants.ATSAPP_API_URL}/comedienne/evenements/getByComedienne/${id}`
    ).pipe(catchError(err => this.handleError(err)));
  }

  getAllPlanningEvents() {
    return this.http.get(
      `${this.constants.ATSAPP_API_URL}/comedienne/evenements/getAll`
    ).pipe(catchError(err => this.handleError(err)));
  }

  createPlanningEvent(body: any, id: number, notify: boolean) {
    return this.http.post(
      `${this.constants.ATSAPP_API_URL}/comedienne/evenements/create/${id}/${
        notify ? 1 : 0
      }`,
      body
    ).pipe(catchError(err => this.handleError(err)));
  }

  deletePlanningEvent(id: number) {
    return this.http.delete(
      `${this.constants.ATSAPP_API_URL}/comedienne/evenements/delete/${id}/0`
    ).pipe(catchError(err => this.handleError(err)));
  }

  patchPlanningEvent(body: any, id: number, notify: boolean) {
    return this.http.post(
      `${this.constants.ATSAPP_API_URL}/comedienne/evenements/patch/${id}/${
        notify ? 1 : 0
      }`,
      body
    ).pipe(catchError(err => this.handleError(err)));
  }

  getSpeakerById(id: number) {
    return this.http.get(
      `${this.constants.ATSAPP_API_URL}/comedienne/getComedienneById/${id}`
    ).pipe(catchError(err => this.handleError(err)));
  }

  getSpeakerByEmail(email: string) {
    return this.http.get(
      `${this.constants.ATSAPP_API_URL}/comedienne/getComedienneByEmail/${email}`
    ).pipe(catchError(err => this.handleError(err)));
  }

  createSpeaker(body: any) {
    return this.http.post(
      `${this.constants.ATSAPP_API_URL}/comedienne/create`,
      body
    ).pipe(catchError(err => this.handleError(err)));
  }

  patchSpeaker(body: any, id: number) {
    return this.http.post(
      `${this.constants.ATSAPP_API_URL}/comedienne/patch/${id}`,
      body
    ).pipe(catchError(err => this.handleError(err)));
  }

  getMedley(codespeak: string) {
     return this.http.get(
       `${this.constants.ATSAPP_API_URL}/comedienne/getMedley/${codespeak}`
     ).pipe(catchError(err => this.handleError(err)));
  }

  disableSpeaker(id: number) {
    return this.http.post(
      `${this.constants.ATSAPP_API_URL}/comedienne/disable/${id}`,
      ''
    ).pipe(catchError(err => this.handleError(err)));
  }

  getUtilisat(trigramme: string) {
    return this.http.get(
      `${this.constants.ATSAPP_API_URL}/utilisats/${trigramme}`
    ).pipe(catchError(err => this.handleError(err)));
  }

  getUtilisats() {
    return this.http.get(`${this.constants.ATSAPP_API_URL}/utilisats`).pipe(catchError(err => this.handleError(err)));
  }

  getEditeurs() {
    return this.http.get(`${this.constants.ATSAPP_API_URL}/utilisats/get/editeurs`).pipe(catchError(err => this.handleError(err)));
  }

  getZappauth() {
    return this.http.get(`${this.constants.ATSAPP_API_URL}/auth/zappauth/all`).pipe(catchError(err => this.handleError(err)));
  }

  assignEditorToSeance(id: number, trigramme: string) {
    return this.http.patch(
      `${this.constants.ATSAPP_API_URL}/comedienne/seances/${id}/${trigramme}`,
      ''
    ).pipe(catchError(err => this.handleError(err)));
  }

  validateRemasterSeance(id: number) {
    return this.http.get(
      `${this.constants.ATSAPP_API_URL}/comedienne/seances/disableRemastering/${id}`
    ).pipe(catchError(err => this.handleError(err)));
  }

  getRecords() {
    return this.http.get(`${this.constants.ATSAPP_API_URL}/botmanager/records`).pipe(catchError(err => this.handleError(err)));
  }

  patchRecord(id: number, body: any) {
    return this.http.patch(
      `${this.constants.ATSAPP_API_URL}/botmanager/record/${id}`,
      body
    ).pipe(catchError(err => this.handleError(err))).pipe(catchError(err => this.handleError(err)));
  }

  createRecord(body: any) {
    return this.http.post(
      `${this.constants.ATSAPP_API_URL}/botmanager/record`,
      body
    ).pipe(catchError(err => this.handleError(err)));
  }

  getRecordStatuses() {
    return this.http.get(
      `${this.constants.ATSAPP_API_URL}/botmanager/statuses`
    ).pipe(catchError(err => this.handleError(err)));
  }

  getGlobalStats() {
    return this.http.get(
      `${this.constants.ATSAPP_API_URL}/comedienne/getGlobalStats`
    ).pipe(catchError(err => this.handleError(err)));
  }

  getStatsSpeakByAdm(body: any) {
    return this.http.post(`${this.constants.ATSAPP_API_URL}/comedienne/stats/getAllActivites`,
    body
    ).pipe(catchError(err => this.handleError(err)));
  }
  getStatsSpeakBySpeak(body: any) {
    return this.http.post(
      `${this.constants.ATSAPP_API_URL}/comedienne/stats/getMyActivites`,
      body
    ).pipe(catchError(err => this.handleError(err)));
  }

  getReleveForSpeakByAdm(body: any) {
    body = body.period.split('-')
    const filters = {
        "annee": body[0],
        "mois": body[1],
    }
    return this.http.post(`${this.constants.ATSAPP_API_URL}/comedienne/stats/getAllReleves`,
      filters
    ).pipe(catchError(err => this.handleError(err)));
  }

  getReleveForSpeakBySpk(body: any) {
    body = body.split('-')
    const filters = {
      "annee": body[0],
      "mois": body[1],
    }
    return this.http.post(`${this.constants.ATSAPP_API_URL}/comedienne/stats/getMyReleves`,
      filters
    ).pipe(catchError(err => this.handleError(err)));
  }
  validateReleveSpeak(body: any) {
    return this.http.post(`${this.constants.ATSAPP_API_URL}/comedienne/stats/validateReleve`,
      body
    ).pipe(catchError(err => this.handleError(err)));
  }
  deleteAFileFromReleveSpeak(fileId: string) {
    return this.http.delete(`${this.constants.ATSAPP_API_URL}/comedienne/releve/file/${fileId}`
    ).pipe(catchError(err => this.handleError(err)));
  }

  validatePayementForSpeak(idReleve:string) {
    return this.http.get(`${this.constants.ATSAPP_API_URL}/comedienne/releve/validateReleveCompta/${idReleve}`
    ).pipe(catchError(err => this.handleError(err)));
  }

  getActiveUsers() {
    return this.http.get(`${this.constants.ATSAPP_API_URL}/auth/zappauth/active`)
      .pipe(catchError(err => this.handleError(err)));
  }

  generateOnAir(body: any) {
    return this.http.post(
      `${this.constants.ATSAPP_API_URL}/comedienne/seance/generateOnAir`,
      body
    ).pipe(catchError(err => this.handleError(err)));
  }

  canDisplayOnAir() {
    return this.http.get(`${this.constants.ATSAPP_API_URL}/comedienne/seance/canGenerateOnAir`)
      .pipe(catchError(err => this.handleError(err)));
  }

  autoTranslateTexts(body: any) {
    return this.http.post(
      `${this.constants.ATSAPP_API_URL}/comedienne/translations/automatic-translations`,
      body
    ).pipe(catchError(err => this.handleError(err)));
  }

  isActiveOnAirDays() {
    return this.http.get(`${this.constants.ATSAPP_API_URL}/params/active_days_onair`)
      .pipe(catchError(err => this.handleError(err)));
  }

  isActiveOnAirALLDays() {
    return this.http.get(`${this.constants.ATSAPP_API_URL}/params/active_global_onair`)
      .pipe(catchError(err => this.handleError(err)));
  }

  setActiveOnAirDays(body : { days: string[]; }) {
    return this.http.patch(
      `${this.constants.ATSAPP_API_URL}/params/active_days_onair`,
      body
    ).pipe(catchError(err => this.handleError(err)));
  }

  setActiveOnAirALLDays() {
    return this.http.patch(
      `${this.constants.ATSAPP_API_URL}/params/active_global_onair`,'',
    ).pipe(catchError(err => this.handleError(err)));
  }

  // Tablettes
  getZappTablette(cc_type: string) {
    return this.http.get(
      `${this.constants.ATSAPP_API_URL}/tablettes/zapptablette/${cc_type}`
    ).pipe(catchError(err => this.handleError(err)));
  }
  sendEmailValidationForASpeak(body: any) {
    return this.http.post(`${this.constants.ATSAPP_API_URL}/comedienne/stats/send_validation_mail_releves`, body)
      .pipe(catchError(err => this.handleError(err)));
  }

  validateReleveFromMail(body: any, token: string) {
    return this.http.post(`${this.constants.ATSAPP_MAIL_VALIDATE_URL}/mailToken/stats/validation_comedienne_mail_releves/${token}`, body)
      .pipe(catchError(err => this.handleError(err)));
  }

  deleteModeFacturation(id: number) {
    return this.http.delete(`${this.constants.ATSAPP_API_URL}/comedienne/modefacturation/${id}`
    ).pipe(catchError(err => this.handleError(err)));
  }

  addModeFacturation(body: any) {
    return this.http.post(`${this.constants.ATSAPP_API_URL}/comedienne/modefacturation/create`, body
    ).pipe(catchError(err => this.handleError(err)));
  }

}
