import {Component, OnInit} from '@angular/core';
import { CommonModule} from "@angular/common";
import {LoaderComponent} from "@common/components/loader/loader.component";
import {ReactiveFormsModule, Validators, FormBuilder} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {AtsappService} from "@common/services/atsapp.service";
import {ToastService} from "../../services/toast.service";

@Component({
  selector: 'app-email-validation-releve',
  templateUrl: './email-validation-releve.component.html',
  styleUrls: ['./email-validation-releve.component.scss'],
  standalone: true,
  imports: [CommonModule,
    LoaderComponent,
    ReactiveFormsModule]

})
export class EmailValidationReleveComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private atsAppService: AtsappService,
    private toastService: ToastService,
  ) {
  }
  paramsRoute : string = this.activatedRoute.snapshot.params['token']

  annee: string = '';
  mois: string = '';
  codeSpeak: string = '';
  signature: string = '';
  prenom: string = '';
  valide: boolean = true;

  isLoading: boolean = false;
  success: boolean = true;
  isSubmit: boolean = false;
  errorMsg: string = '';
  refuseForm = this.formBuilder.group({
    comment : ['', Validators.required],
  })

  ngOnInit() {

    this.valide = JSON.parse(this.activatedRoute.snapshot.queryParamMap.get('valide')!);
    this.codeSpeak = this.activatedRoute.snapshot.queryParamMap.get('codeSpeak')!;
    this.mois = this.activatedRoute.snapshot.queryParamMap.get('mois')!;
    this.annee =  this.activatedRoute.snapshot.queryParamMap.get('annee')!;
    this.prenom =  this.activatedRoute.snapshot.queryParamMap.get('prenom')!;
    this.signature = this.activatedRoute.snapshot.queryParamMap.get('signature')!;


    if (this.valide) {
      this.isLoading = true;
      const body = {
        'valide' : this.valide,
        'codeSpeak' : this.codeSpeak,
        'mois' : this.mois,
        'annee' : this.annee,
        'signature' : this.signature
      }
      this.sendRequestReleve(body)
    }
  }

  submitRejectionReleve() {
    const body = {
      'valide' : this.valide,
      'codeSpeak' : this.codeSpeak,
      'mois' : this.mois,
      'annee' : this.annee,
      'signature' : this.signature,
      'commentaire' : this.refuseForm.controls.comment.value,
    }

    this.sendRequestReleve(body)
  }

  sendRequestReleve(body: any) {
    this.isSubmit = true;

    this.atsAppService.validateReleveFromMail(body, this.paramsRoute).subscribe({
      next: (res) => {
        if (res?.errorMessage) {
          this.isLoading = false;
          this.success = false;
          this.errorMsg = res.errorMessage;
        }
        else {
          this.isLoading = false;
          this.success = true;
        }
      }
    })
  }

  getMonthName(monthNumber: string): string {
    const months = [
      'Janvier', 'Février', 'Mars', 'Avril',
      'Mai', 'Juin', 'Juillet', 'Août',
      'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];
    const monthIndex = parseInt(monthNumber, 10);
    return months[monthIndex - 1];
  }


}
