<div class="admindashboard container">
  <div class="row mt-3">
    <div class="col-7">
      <div class="row">
        <div class="col-12">
          <h2>Disponibilités</h2>
          <div class="papercard">
            <div class="admindashboard__stat">
              <div class="admindashboard__stat__item">
                {{ this.defaultAvailable + this.available - this.unavailable }}
                <span>Disponibles</span>
              </div>
              <div class="admindashboard__stat__item">
                {{ this.onSite }}
                <span>Au studio</span>
              </div>
              <div class="admindashboard__stat__item">
                {{ this.leave }}
                <span>Absent·e·s</span>
              </div>
              <div class="admindashboard__stat__item">
                {{ this.sickLeave }}
                <span>Malades</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h2>État des séances vocales</h2>
          <div class="papercard">
            <table class="table table-striped">
              <thead>
              <tr>
                <th>Langue</th>
                <th>En attente</th>
                <th>A masteriser</th>
                <th>A attribuer</th>
                <th>En-cours de découpe</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of this.seancesPV | keyvalue">
                <td>{{ $any(item.value).Langue }}</td>
                <td>{{ $any(item.value).EnAttente }}</td>
                <td>{{ $any(item.value).ARemasteriser }}</td>
                <td>{{ $any(item.value).AAttribuer }}</td>
                <td>{{ $any(item.value).EnCoursDecoupe }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h2>Prises voix</h2>
          <div class="papercard">
            <table *ngIf="pvStats?.TELEPHONIE" class="table table-striped caption-top">
              <caption><u><b>Téléphonie</b></u></caption>
              <thead>
                <tr>
                  <th>Langue</th>
                  <th>Féminin</th>
                  <th>Masculin</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of this.pvStats?.TELEPHONIE?.PV | keyvalue">
                  <td>{{ $any(item.value).Langue }}</td>
                  <td>{{ $any(item.value)["Féminin"] }}</td>
                  <td>{{ $any(item.value).Masculin }}</td>
                </tr>
              </tbody>
            </table>
            <table *ngIf="pvStats?.SPOT_RADIO" class="table table-striped caption-top">
              <caption><u><b>Spot radio</b></u></caption>
              <thead>
              <tr>
                <th>Langue</th>
                <th>Féminin</th>
                <th>Masculin</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of this.pvStats?.SPOT_RADIO?.PV | keyvalue">
                <td>{{ $any(item.value).Langue }}</td>
                <td>{{ $any(item.value)["Féminin"] }}</td>
                <td>{{ $any(item.value).Masculin }}</td>
              </tr>
              </tbody>
            </table>
            <table  *ngIf="pvStats?.PODCAST" class="table table-striped caption-top">
              <caption><u><b>Podcast</b></u></caption>
              <thead>
              <tr>
                <th>Langue</th>
                <th>Féminin</th>
                <th>Masculin</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of this.pvStats?.PODCAST?.PV | keyvalue">
                <td>{{ $any(item.value).Langue }}</td>
                <td>{{ $any(item.value)["Féminin"] }}</td>
                <td>{{ $any(item.value).Masculin }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
    <div class="col-5">
      <div class="row">
        <div class="col-12">
          <h2>Au studio aujourd'hui</h2>
          <div class="papercard">
            <ul *ngIf="this.onSiteDetails.length > 0; else nobodyOnSite" class="admindashboard__onsitetoday">
              <li *ngFor="let spk of this.onSiteDetails">
                {{ spk.speaker }}
                <span class="badge text-bg-info">
                  {{ format(parseISO(spk.hour), "HH'h'mm") }}
                </span>
              </li>
            </ul>
            <ng-template #nobodyOnSite>
              <div class="text-center fst-italic text-secondary">Personne ne sera présent au studio aujourd'hui</div>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h2>Traductions</h2>
          <div class="papercard">
            <table *ngIf="pvStats?.TELEPHONIE?.TRAD && Object.keys(pvStats?.TELEPHONIE?.TRAD).length > 0" class="table table-striped caption-top">
              <caption><u><b>Téléphonie</b></u></caption>
              <thead>
                <tr>
                  <th>Langue</th>
                  <th>Nombre</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of pvStats?.TELEPHONIE?.TRAD | keyvalue">
                  <td>{{ $any(item.value).Langue }}</td>
                  <td>{{ $any(item.value).Nombre }}</td>
                </tr>
              </tbody>
            </table>
            <table *ngIf="pvStats?.PODCAST?.TRAD && Object.keys(pvStats?.PODCAST?.TRAD).length > 0" class="table table-striped caption-top">
              <caption><u><b>Podcast</b></u></caption>
              <thead>
              <tr>
                <th>Langue</th>
                <th>Nombre</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of pvStats?.PODCAST?.TRAD | keyvalue">
                <td>{{ $any(item.value).Langue }}</td>
                <td>{{ $any(item.value).Nombre }}</td>
              </tr>
              </tbody>
            </table>
            <table *ngIf="pvStats?.SPOT_RADIO?.TRAD && Object.keys(pvStats?.SPOT_RADIO?.TRAD).length > 0" class="table table-striped caption-top">
              <caption><u><b>Spot radio</b></u></caption>
              <thead>
              <tr>
                <th>Langue</th>
                <th>Nombre</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of pvStats?.SPOT_RADIO?.TRAD | keyvalue">
                <td>{{ $any(item.value).Langue }}</td>
                <td>{{ $any(item.value).Nombre }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h2>État des séances de traduction</h2>
          <div class="papercard">
            <table class="table table-striped">
              <thead>
              <tr>
                <th>Langue</th>
                <th>En attente</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of this.seancesTranslation | keyvalue">
                <td>{{ $any(item.value).Langue }}</td>
                <td>{{ $any(item.value).EnAttente }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
