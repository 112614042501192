import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextsListComponent } from 'projects/speaker-platform/src/app/organisms/texts-list/texts-list.component';
import { TextsTodo } from 'projects/speaker-platform/src/app/types/texts-todo.enum';
import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
import { AtsappService } from '@common/services/atsapp.service';
import { AbstractTextComponent } from '../../common/abstract/abstract-texts.component';
import { DateTransformService } from 'projects/speaker-platform/src/app/services/helpers/date-transform.service';
import { DialogComponent } from 'projects/speaker-platform/src/app/molecules/dialog/dialog.component';
import { LoaderComponent } from '@common/components/loader/loader.component';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Seance } from 'projects/speaker-platform/src/app/types/seance';
import { SeanceStatuses } from 'projects/speaker-platform/src/app/types/seanceStatuses.enum';
import { PostStatesComponent } from 'projects/speaker-platform/src/app/molecules/post-states/post-states.component';
import { CcLibelleService } from 'projects/speaker-platform/src/app/services/helpers/cc-libelle-service.service';
import { UserService } from '@common/services/user.service';
import { ToastService } from '../../services/toast.service';
import {firstValueFrom, concatMap, takeUntil} from 'rxjs';

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { PriseVoix } from '../../types/prisesVoix';
import { SpeakerService } from '@common/services/helpers/speaker.service';
import { format } from 'date-fns';
import {NgbTooltip} from "@ng-bootstrap/ng-bootstrap";

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-texts-to-record',
  standalone: true,
  imports: [
    CommonModule,
    TextsListComponent,
    DialogComponent,
    LoaderComponent,
    FormsModule,
    ReactiveFormsModule,
    PostStatesComponent,
    NgbTooltip,
  ],
  templateUrl: './texts-to-record.component.html',
  styleUrls: ['./texts-to-record.component.scss'],
})
export class TextsToRecordComponent
  extends AbstractTextComponent
  implements OnInit, OnDestroy
{
  parseInt(arg0: string): number {
    return parseInt(arg0);
  }
  constructor(
    atsappService: AtsappService,
    dateTransformService: DateTransformService,
    ccLibelleService: CcLibelleService,
    private fb: FormBuilder,
    private userService: UserService,
    private toast: ToastService
  ) {
    super(atsappService, dateTransformService, ccLibelleService);
  }

  textsTodo = TextsTodo;
  assignedPVs: any = [];
  standByPV: any = [];
  seances: Seance[] = [];
  selectedSeance!: Seance;

  standbyDialogVisible: boolean = false;
  commentDialogVisible: boolean = false;
  readerDialogVisible: boolean = false;

  isFetching: boolean = false;

  isPosting: boolean = false;
  isPostingOver: boolean = false;
  postError: { errorCode: number; message: string } | null = null;

  pvToPost: any;

  standbyPvs: number = 0;

  standbyForm = this.fb.group({
    standbyTypeInput: ['', Validators.required],
    standbyMessageInput: [''],
  });

  speakerId!: number;

  displayPdfSource: boolean = false;

  ngOnInit(): void {
    if (this.userService.userData?.speakerId !== -1) {
      this.getSeances();
    }
    this.userService.userDataLoaded.subscribe((loaded: boolean) => {
      loaded && this.getSeances();
    });
    this.getStandbyTypes();
    this.getPhasesVocale();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  async getSeances() {
    if (!this.userService.userData) {
      return;
    }

    try {
      this.isFetching = true;
      const res = await firstValueFrom(
        this.atsappService.getSeanceBySpeaker(this.userService.userData.speakerId)
      );

      const filtered = res.filter(
        (seance: Seance) =>
          seance.mode === 'VOCAL' && seance.statut.includes(SeanceStatuses.SENT)
      );
      this.seances = filtered;

      if (filtered.length > 0) {
        await this.getPvsBySeance(filtered[0].id);
      }
    } catch (error) {
      this.toast.show(
        'Le chargement des séances à échoué pour la raison suivante: ' +
        error,
        'danger'
      );
    } finally {
      this.isFetching = false;
    }
  }

  async getPvsBySeance(id: number) {
    try {
      this.isFetching = true;
      this.standbyPvs = 0;
      this.selectedSeance = this.seances.filter(
        (seance: Seance) => seance.id === id
      )[0];

      const [seancePVs, standBy] = await Promise.all([
        firstValueFrom(this.atsappService.getPvsBySeance(id)),
        firstValueFrom(this.atsappService.getStandByBySeance(this.selectedSeance.id))
      ]);

      for (let pv of seancePVs.seancePVs) {
        if (
          pv.ZMS_ETATMESSAGE.replaceAll(' ', '') &&
          pv.ZMS_STDBYPV1 === 'X'
        ) {
          this.standbyPvs += 1;
        }
      }

      this.assignedPVs = seancePVs.seancePVs.filter(
        (pv: PriseVoix) =>
          !pv.ZMS_PVDATERETOUR1 ||
          pv.ZMS_PVDATERETOUR1 === '' ||
          new Date(pv.ZMS_PVDATERETOUR1).getFullYear() === 1900
      );

      this.standByPV = standBy;
    } catch (error) {
      this.toast.show(
        'Le chargement des prises voix à échoué pour la raison suivante: ' +
        error,
        'danger'
      );
    } finally {
      this.isFetching = false;
    }
  }

  onShowStandbyDialog(pv: any): void {
    this.standbyForm.reset({ standbyTypeInput: '' });
    this.postError = null;
    this.isPosting = false;
    this.isPostingOver = false;

    this.standbyDialogVisible = true;
    this.pvToPost = pv;
  }

  onShowCommentDialog(pv: any): void {
    this.postError = null;
    this.isPosting = false;
    this.isPostingOver = false;

    this.commentDialogVisible = true;
    this.pvToPost = pv;
  }

  standby(): void {
    this.isPosting = true;
    this.isPostingOver = false;

    const body = {
      PRISE_VOIX: {
        ZMS_NUMERO: this.pvToPost.ZMS_NUMERO,
        ZMS_LIVRAISON: this.pvToPost.ZMS_LIVRAISON,
        ZMS_MESSAGE: this.pvToPost.ZMS_MESSAGE,
        STANDBY_MESSAGE: this.standbyForm.get('standbyMessageInput')?.value,
        ZMS_CREATEUR: this.pvToPost.ZMS_CREATEUR,
        STANDBY_TYPE: this.standbyForm.get('standbyTypeInput')?.value,
        STANDBY_TYPE_LIBELLE: this.ccLibelleService.getCcLibelle(
          //@ts-expect-error
          this.standbyForm.get('standbyTypeInput')?.value,
          this.standbyTypes
        ),
        YTC_REPRESENTANT2: this.pvToPost.YTC_REPRESENTANT2,
        RANG_PV: this.pvToPost.RANGPV,
        LANGUE: this.pvToPost.LANGUE,
        ZMS_PVTEXTE: this.pvToPost.ZMS_PVTEXTE,
        GENRE: this.pvToPost.GENRE,
        CLIENT_FINAL: this.pvToPost.CLIENT_FINAL,
        LIBELLE_CLIENT_FINAL: this.pvToPost.LIBELLE_CLIENT_FINAL,
        ZMS_PVPHONETIQUE: this.pvToPost.ZMS_PVPHONETIQUE,
        ID_SEANCE: this.selectedSeance.id
      },

    };


    this.atsappService
      .standbyPV(body)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: (error) => {
          this.postError = {
            errorCode: error.error.code,
            message: error.error.message,
          };
          this.isPosting = false;
          this.playErrorSound();
        },
        complete: () => {
          this.postError = null;
          this.isPostingOver = true;
          this.isPosting = false;
          this.playSuccessSound();
          this.getPvsBySeance(this.selectedSeance.id);
        },
      });
  }

  savePDF() {
    let typeSeanceLibelle = '';
    if (this.selectedSeance.type === 'ON AIR') {
      typeSeanceLibelle = 'On Air ';
    }
    let dd: any = {
      header: {
        text:
          'Séance ' + typeSeanceLibelle +
          this.userService.userData.libelle + ' ' +
          this.selectedSeance.langue +
          ' du ' +
          format(new Date(this.selectedSeance.date_creation), 'dd/MM/yyyy'),
        style: 'pageHeader',
        margin: [16, 16],
      },

      footer: function (currentPage: number, pageCount: number) {
        return 'Page ' + currentPage.toString() + ' sur ' + pageCount;
      },
      content: [],

      styles: {
        subHead: {
          bold: true,
          background: 'yellow',
        },
        pageHeader: {
          bold: true,
          fontSize: 18,
        },
      },
    };

    this.assignedPVs.forEach((pv: PriseVoix) => {
      dd.content.push(
        {
          table: {
            widths: ['*', '*', '*'],
            headerRows: 1,
            pageBreak: 'after',
            dontBreakRows: true,
            unbreakable: true,
            keepWithHeaderRows: 1,
            body: [
              [
                {
                  text: 'Référence : ' + pv.ZMS_PVCODE1,
                  fillColor: '#FFED00',
                  border: [false, false, false, false],
                },
                {
                  text:
                    'Phase vocale : ' +
                    this.ccLibelleService.getCcLibelle(
                      pv.ZMS_PHASEVOCALE,
                      this.phasesVocalesList
                    ),
                  fillColor: '#FFED00',
                  border: [false, false, false, false],
                },
                {
                  text:  pv.PROVISOIRE === 'OUI' ? 'Provisoire' : '',
                  fillColor: '#FFED00',
                  border: [false, false, false, false],
                }
              ],
              [
                {
                  text: 'Texte : \n\n' + pv.ZMS_PVTEXTE.replace(/\n\s*\n/g, '\n'),
                  border: [false, false, false, false],
                  colSpan: 3,
                  margin: [0, 3],
                },
                {},
                {},
              ],
              [
                {
                  text: 'Phonétique / consignes : \n' + pv.ZMS_PVPHONETIQUE.replace(/\n\s*\n/g, '\n'),
                  fillColor: '#e0d6d0',
                  colSpan: 3,
                  border: [false, false, false, true],
                  margin: [0, 0, 0, 8],
                },
                {},
                {},
              ],
            ],
          },
        },
        { text: '\n' }
      );
    });

    pdfMake.createPdf(dd).download(`Seance ${this.selectedSeance.type === 'ON AIR' ? 'On Air ' : ''}${this.selectedSeance.langue} du ${format(new Date(this.selectedSeance.date_creation), 'dd/MM/yyyy')}.pdf`);
  }

  get standbyTypeInput() {
    return this.standbyForm.get('standbyTypeInput');
  }
}
