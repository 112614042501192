<div class="statslist page container">
  <div class="statslist__header">
    <div class="row">
      <div class="col-12">
        <app-statistic-menu [typeUser]="this.typeUser"></app-statistic-menu>
      </div>
    </div>
  </div>
  <form [formGroup]="periodValidation" (submit)="submitJustificatif()">
    <div class="row mb-3 align-items-end">
      <div class="col-4">
        <label class="form-label" for="">Période à valider :</label>
        <app-month-input (dateSelected)="onDateSelected($event)" />
      </div>
      <div class="col-1"></div>
      <div class="col-2 p-0">
        <button class="btn btn-primary" type="button" (click)="getStatsResume()" [disabled]="periodValidation.controls.period.invalid" >
          Rechercher
        </button>
      </div>
    </div>
    <div *ngIf="displayDetail" class="row align-items-end mt-4">
      <div class="mt-5 mb-2">
        <app-resume-statistics-speaker *ngIf="displayDetail" [statsForASpeaker]="statsSpeak" [releve]="releve"></app-resume-statistics-speaker>
      </div>
      <div class="row mt-4 align-items-end">
        <div class="col-12" *ngIf="releve?.dateValidationComedienne !== null">
          <h6>Activité validé le {{releve?.dateValidationComedienne | date : 'dd/MM/YYYY à HH:mm:ss'}} pour le mois sélectionné.</h6>
          <p class="mt-2">Liste des fichiers : </p>
          <ul>
            <li class="mt-1" *ngFor="let file of releve.fichiers; let i = index">
              <div class="row align-items-center justify-content-between">
                <div class="col-10">
                  <a [href]="file.urlSrc" download="oui">{{ getFileName(file.cheminFichier)}}</a> <i> ajouté le : {{file?.dateAjout | date : 'dd/MM/YYYY à HH:mm'}} :</i>
                </div>
                <div class="col-1">
                  <button type="button" (click)="openDeleteAFileModal(deleteAFileModal, file)" class="btn btn-danger" ngbTooltip="Supprimer {{ getFileName(file.cheminFichier)}}">
                    <i class="fa-solid fa-trash"></i>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="row align-items-end" *ngIf="releve !== undefined">
          <div class="col-6 mt-2">
            <label for="formFileMultiple" class="form-label">Fichier(s) justificatif :</label>
            <input class="form-control" (change)="loadedJustificatif($event)" type="file" id="formFileMultiple" multiple>
          </div>
          <div class="col-2 p-0">
            <button class="btn btn-primary" type="submit" [disabled]="releve?.dateValidationComedienne !== null && !markChangeFile"  >
              {{releve?.dateValidationComedienne !== null ? 'Ajouter un fichier' : 'Valider l\'activité'}}
            </button>
          </div>
          <div class="col-1"></div>
          <div class="col-3 ps-4">
            <button class="btn btn-primary me-2" type="button" (click)="this.getPdf()" ngbTooltip="Télécharger le relevé en PDF">
              <i class="fa-solid fa-file-pdf me-2"></i>Générer le relevé</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #deleteAFileModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Suppression justificatif</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('')"></button>
  </div>
  <div class="modal-body">
    Voulez vous supprimer le fichier  ?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="deleteAFile(selectedFileToDelete)">Supprimer</button>
  </div>
</ng-template>



