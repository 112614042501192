import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewChecked,
  ChangeDetectorRef,
  TemplateRef
} from '@angular/core';
import { CommonModule, NgFor } from '@angular/common';
import { AtsappService } from '@common/services/atsapp.service';
import { takeUntil } from 'rxjs';
import {
  ModalDismissReasons, NgbDateStruct,
  NgbDropdownModule,
  NgbInputDatepicker,
  NgbModal,
  NgbPagination,
  NgbTooltip
} from '@ng-bootstrap/ng-bootstrap';
import { FiltersProps } from 'projects/speaker-platform/src/app/types/filters';
import { DateTransformService } from 'projects/speaker-platform/src/app/services/helpers/date-transform.service';
import { TextsListFiltersComponent } from 'projects/speaker-platform/src/app/organisms/admin/texts-list-filters/texts-list-filters.component';
import { AbstractTextComponent } from '../../../common/abstract/abstract-texts.component';
import { PaginationComponent } from 'projects/speaker-platform/src/app/molecules/pagination/pagination.component';
import { TextsMenuComponent } from 'projects/speaker-platform/src/app/molecules/texts-menu/texts-menu.component';
import { DialogComponent } from 'projects/speaker-platform/src/app/molecules/dialog/dialog.component';
import { SpeakerService } from 'projects/speaker-platform/src/app/services/helpers/speaker.service';
import { TextsTodo } from 'projects/speaker-platform/src/app/types/texts-todo.enum';
import { LoaderComponent } from '@common/components/loader/loader.component';
import { PostStatesComponent } from 'projects/speaker-platform/src/app/molecules/post-states/post-states.component';
import { Speaker } from '../../../types/speaker';
import { TruncatePipe } from '../../../pipes/truncate.pipe';
import { CcLibelleService } from '../../../services/helpers/cc-libelle-service.service';
import { TranslationStatuses } from '../../../types/translationStatuses.enum';
import { isSameDay, isWithinInterval, startOfToday, format } from 'date-fns';
import { PlanningEvent } from '../../../types/planning-event';
import { PlanningEventReasons } from '@common/types/planning-event-reasons.enum';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModalService} from "../../../services/helpers/common-modal.service";
import {TextFocusComponent} from "../../../organisms/text-focus/text-focus.component";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: 'app-textstosend',
  standalone: true,
  imports: [
    CommonModule,
    NgbPagination,
    TextsListFiltersComponent,
    PaginationComponent,
    TextsMenuComponent,
    DialogComponent,
    LoaderComponent,
    PostStatesComponent,
    TruncatePipe,
    NgbTooltip,
    NgbDropdownModule,
    FormsModule,
    TextFocusComponent,
    ReactiveFormsModule,
    NgbInputDatepicker
  ],
  templateUrl: './texts-to-send.component.html',
  styleUrls: ['../../../organisms/admin/texts-list/texts-list.component.scss'],
})
export class TextsToSendComponent
  extends AbstractTextComponent
  implements OnDestroy, OnInit, AfterViewChecked
{
  constructor(
    atsappService: AtsappService,
    protected speakerService: SpeakerService,
    dateTransformService: DateTransformService,
    ccLibelleService: CcLibelleService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    protected commonModalService: CommonModalService
) {
    super(atsappService, dateTransformService, ccLibelleService);
  }

  @ViewChild('postDialog')
  postDialog: DialogComponent = new DialogComponent();

  @ViewChild('textsListHeader') textsListHeader!: ElementRef;

  translationStatuses = TranslationStatuses;

  smartSelectEnabled: boolean = false;
  textsTodo = TextsTodo;
  showValidationDialog: boolean = false;
  dateEstimation: any;
  textsToSendBody: any;
  isPosting: boolean = false;
  isPostingOver: boolean = false;
  postError: { errorCode: number; message: string } | null = null;
  speakerCountToday: number = 0;
  speakerCountTodayDetails: {seanceIndex: number; remain: number}[] = []
  speakerTextsBySeance: number = 0;
  speakerSeancesLimit: number = 0;
  textsForPreview: any[] = [];
  showModalPreviewTexts: boolean = false;

  selectedEditor: string = '';
  speakerAvailable: boolean = true;
  planningEventReasons = PlanningEventReasons;
  now: Date = new Date();

  seanceDuo: string | null = null;
  seanceTrio: string | null = null;
  seanceHasBeenChecked: boolean = false;
  createNewSeance: boolean = false;

  showFocusModalFromToSend: boolean = false;

  // Results from API
  prisesVoix: any[] = [];
  prisesVoixGrouped: any[] = [];

  // Pagination informations
  page: number = 1;
  limit: number = 10;
  collectionSize: number = 0;

  submited: boolean = false;

  // To POST
  pvArrayToPost: Array<{
    RANG_PV: string;
    ZMS_DATEPVDIST1: string;
    ZMS_LIVRAISON: string;
    ZMS_MESSAGE: string;
    ZMS_NUMERO: string;
  }> = [];

  // Provided in template by app-texts-list-filters
  selectedSpeaker!: Speaker | undefined;
  filters: any = {};
  langSelected: boolean = false;
  categorySelected: boolean = true;

  textsListHeaderHeight: string = '';

  postObjectKeys = [
    'ZMS_SPEAKRINE',
    'ZMS_PVCHOIXCLIENT1',
    'RANGPV',
    'ZMS_NUMERO',
    'ZMS_LIVRAISON',
    'ZMS_MESSAGE',
  ];

  ngOnInit(): void {
    // Je récupère la date "normale" de JS
    const now = new Date();

    now.setDate(now.getDate() + 1);

    // J'assigne cette date au format attendu par ngbDatepicker
    this.dateEstimation = {
      year:  now.getFullYear(),
      month: now.getMonth() + 1, // +1 impératif, car en JS: janvier=0, en Ngb: janvier=1
      day:   now.getDate(),
    };

    this.getPhasesVocale();
    this.getTypesMessage();
    this.getLangues();
    this.getSpeakers();
    this.getCategoryPV()
  }

  ngAfterViewChecked(): void {
    this.textsListHeaderHeight =
      this.textsListHeader.nativeElement.clientHeight + 'px';
    this.cdr.detectChanges();
  }

  onSelectLang(e: any) {
    // Keep this in case of change of mind
    // Otherwise, delete with listener / emiter from texts-list-filters component
    // if (e !== '') {
    //   this.langSelected = true;
    //   return;
    // }
    // this.langSelected = false;
  }

  isCegidNullDate(date: string): boolean {
    return format(new Date(date), 'yyyy') === '1900';
  }

  getPVsList(filters: any = this.filters) {
    this.page = 1;
    this.filters = filters;
    this.isLoading = true;
    this.langSelected = this.filters.ZMS_LANGUE !== '' ? true : false;
    this.categorySelected = this.filters.ZMS_CATEGORIE !== '' ? true : false;
    this.atsappService
      .getPVsToAssign(this.filters, this.page, this.limit)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          this.prisesVoix = res.pv_to_assign;
          this.prisesVoixGrouped = res.pv_to_assign;
          this.collectionSize = parseInt(res.total);
          this.isLoading = false;
          if (this.selectedSpeaker !== undefined) {
            this.getSpeakerCountToday();
          }
        },
        error: (error) => {
          console.error(error);
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
          this.resetTextsToPatchList();
        },
      });
  }

  getSpeakerCountToday() {
    if (this.selectedSpeaker) {
      this.atsappService.getSeanceBySpeaker(this.selectedSpeaker.id).subscribe({
        next: (res: any) => {
          const seanceNotOnaAir = res.filter((seance: any) => {return seance.type !== 'ON AIR'})
          this.speakerCountToday = 0;
          const today = startOfToday();
          this.speakerTextsBySeance = this.selectedSpeaker ? this.selectedSpeaker.nbtxtpv : 0;
          this.speakerSeancesLimit = this.selectedSpeaker ? this.selectedSpeaker.nbpvjour : 0;

          for (let i = 0; i < seanceNotOnaAir.length; i++) {
            if (isSameDay(new Date(seanceNotOnaAir[i].date_creation).setHours(0,0,0), today)) {
              this.speakerCountToday += seanceNotOnaAir[i].compteur_todo;
            }
          }

          this.speakerCountTodayDetails = []
          for (let i = 0; i < this.speakerSeancesLimit; i++) {
            this.speakerCountTodayDetails.push({
              seanceIndex: i,
              remain:
                this.speakerTextsBySeance * (i + 1) - this.speakerCountToday,
            });
          }
        },
      });
    }
  }

  openSendTextsDialog() {
    this.postError = null;
    this.isPosting = false;
    this.isPostingOver = false;
    this.selectedSpeaker !== undefined &&
      (this.showValidationDialog = !this.showValidationDialog);
    if (this.selectedSpeaker) {
      this.checkSeance()
    }
  }
  checkSeance() {
    this.seanceHasBeenChecked = false;
    this.seanceDuo = null;
    this.seanceTrio = null;
    this.atsappService.checkSeance({comedienne: this.selectedSpeaker!.codespeak, langue: this.filters.ZMS_LANGUE,
      dateEstimation: `${this.dateEstimation.year}-${this.dateEstimation.month}-${this.dateEstimation.day}`})
      .subscribe({ next: (res: any) => {
        this.seanceDuo = res.seanceDuo ? res.seanceDuo : null
        this.seanceTrio = res.seanceTrio ? res.seanceTrio : null
        this.seanceHasBeenChecked = true;
      }
    })
  }

  sendTexts(notify: boolean = false): void {
    // this.submited = true;
    if (this.selectedSpeaker === undefined) {
      return;
    }

    // Object to POST
    const body: any = {
      CODE_COMEDIENNE: this.selectedSpeaker.codespeak,
      LANGUE: this.filters?.ZMS_LANGUE,
      CATEGORIE_SEANCE: this.filters?.ZMS_CATEGORIE,
      DATE_ESTIMATION: `${this.dateEstimation.year}-${this.dateEstimation.month}-${this.dateEstimation.day}`,
      email: notify,
      // Assign this.selectedSpeaker value to ZMS_SPEAKRINE key
      PRISES_VOIX: [
        ...this.textsToPatchList.map((item: any) => {
          item.ZMS_SPEAKRINE = this.selectedSpeaker?.codespeak;
          return item;
        }),
      ],
    };

    if (this.seanceTrio) {
      body.ID_SEANCE = this.seanceTrio;
    } else if (this.seanceDuo && !this.createNewSeance) {
      body.ID_SEANCE = this.seanceDuo;
    }

    this.isPosting = true;

    this.atsappService
      .assignPVToSpeaker(body)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: (error) => {
          this.playErrorSound();
          this.postError = {
            errorCode: error.error.code,
            message: error.error.message,
          };
          this.isPosting = false;
        },
        complete: () => {
          this.postError = null;
          this.isPostingOver = true;
          this.isPosting = false;
          this.resetTextsToPatchList();
          this.playSuccessSound();
        },
      });
  }

  onSelectSpeaker(speaker: Speaker) {
    this.selectedSpeaker = speaker;
    this.speakerAvailable = true;

    this.atsappService.getPlanningEventsByComedienne(speaker.id).subscribe({
      next: (res: any) => {
        for (let event of res as PlanningEvent[]) {
          if (
            isWithinInterval(startOfToday(), {
              start: new Date(event.date_debut),
              end: new Date(event.date_fin),
            })
          ) {
            if (
              event.raison !== this.planningEventReasons.SEANCE &&
              event.raison !== this.planningEventReasons.ON_SITE
            ) {
              this.speakerAvailable = false;
            }
          }
        }
      },
    });

    this.getSpeakerCountToday();

    // this.atsappService.getSeanceBySpeaker(speaker.id).subscribe({
    //   next: (res: any) => {
    //     this.speakerCountToday = 0;
    //     const today = startOfToday();
    //     this.speakerTextsBySeance = speaker.nbtxtpv;
    //     this.speakerSeancesLimit = speaker.nbpvjour;

    //     for (let i = 0; i < res.length; i++) {
    //       if (isSameDay(new Date(res[i].date_creation), today)) {
    //         this.speakerCountToday = res[i].compteur_todo;
    //         break;
    //       }
    //     }
    //   },
    // });
  }

  filterSelectedText() : any[] {
    this.textsForPreview =[]
    return this.prisesVoix.filter(allSeensText => {
      return this.textsToPatchIdList.some(textToSee => {
        const partialIdText = textToSee.split('-')
        return partialIdText[0] === allSeensText.ZMS_NUMERO && partialIdText[1] === allSeensText.ZMS_LIVRAISON && partialIdText[2] === allSeensText.ZMS_MESSAGE && partialIdText[3]=== allSeensText.RANGPV && partialIdText[4] ===allSeensText.CLIENT_FINAL
      })
    });
  }

  showSelectedText(content : TemplateRef<any>) {
    this.textsForPreview = this.filterSelectedText()
    // this.showModalPreviewTexts = true
    this.modalService.open(content, { size: 'xl', scrollable: true , centered: true})
  }

  testShowModal( text : any) {
    this.commonModalService.showModalFocus.subscribe((show) => {
      this.showFocusModalFromToSend = show;
    })
    this.commonModalService.openFocusText(text)
  }



  groupTexteForCompletion(valueGroup: number) {
    if (valueGroup === 0) {
      this.prisesVoixGrouped = this.prisesVoix
    } else {
      const results: Message[] = [];
      const groupedMessages = this.prisesVoix.reduce((acc, message) => {
        const key = `${message.ZMS_NUMERO}-${message.CLIENT_FINAL}-${message.ZMS_LIVRAISON}`;
        acc[key] = (acc[key] || []).concat(message);
        return acc;
      }, {} as Record<string, Message[]>);
      for (const [, messagesGroup] of Object.entries(groupedMessages)) {
        if (Array.isArray(messagesGroup)) {
          if (messagesGroup.length === Number(valueGroup)) {
            results.push(...messagesGroup);
          }
        }
      }
      this.prisesVoixGrouped = results
    }
    this.collectionSize = this.prisesVoixGrouped.length;
  }

  changePageSize(size: string): void {
    this.limit = Number(size);
    this.getPVsList();
  }

  onSmartSelectEnable() {
    this.limit = this.collectionSize;
    this.resetTextsToPatchList();
    this.getPVsList();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

interface Message {
  ZMS_NUMERO: string;
  CLIENT_FINAL: string;
  ZMS_LIVRAISON: string;
  messageId: string;
}
