<div class="editparameters container" [formGroup]="this.editParameters">
  <div class="row">
    <div class="col-12 mb-3">
      <h6>Jours d'enregistrement par défaut</h6>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col-12">
      <div class="editparameters__recorddays">
        <div class="editparameters__recorddays__day">
          <label
            class="form-check-label d-block"
            for="create-speaker-record-mon"
            >Lundi</label
          >
          <input
            class="form-check-input"
            type="checkbox"
            name="create-speaker-record-mon"
            id="create-speaker-record-mon"
            value="Mon"
            formControlName="__joursenregistrementMon"
            (change)="
              this.updatePristineStatus();
              this.saveMultiCheck(
                $event,
                this.recordDays,
                'disponibilite'
              )
            "
          />
        </div>
        <div class="editparameters__recorddays__day">
          <label
            class="form-check-label d-block"
            for="create-speaker-record-tue"
            >Mardi</label
          >
          <input
            class="form-check-input"
            type="checkbox"
            name="create-speaker-record-tue"
            id="create-speaker-record-tue"
            value="Tue"
            formControlName="__joursenregistrementTue"
            (change)="
              this.updatePristineStatus();
              this.saveMultiCheck(
                $event,
                this.recordDays,
                'disponibilite'
              )
            "
          />
        </div>
        <div class="editparameters__recorddays__day">
          <label
            class="form-check-label d-block"
            for="create-speaker-record-wed"
            >Mercredi</label
          >
          <input
            class="form-check-input"
            type="checkbox"
            name="create-speaker-record-wed"
            id="create-speaker-record-wed"
            value="Wed"
            formControlName="__joursenregistrementWed"
            (change)="
              this.updatePristineStatus();
              this.saveMultiCheck(
                $event,
                this.recordDays,
                'disponibilite'
              )
            "
          />
        </div>
        <div class="editparameters__recorddays__day">
          <label
            class="form-check-label d-block"
            for="create-speaker-record-thu"
            >Jeudi</label
          >
          <input
            class="form-check-input"
            type="checkbox"
            name="create-speaker-record-thu"
            id="create-speaker-record-thu"
            value="Thu"
            formControlName="__joursenregistrementThu"
            (change)="
              this.updatePristineStatus();
              this.saveMultiCheck(
                $event,
                this.recordDays,
                'disponibilite'
              )
            "
          />
        </div>
        <div class="editparameters__recorddays__day">
          <label
            class="form-check-label d-block"
            for="create-speaker-record-fri"
            >Vendredi</label
          >
          <input
            class="form-check-input"
            type="checkbox"
            name="create-speaker-record-fri"
            id="create-speaker-record-fri"
            value="Fri"
            formControlName="__joursenregistrementFri"
            (change)="
              this.updatePristineStatus();
              this.saveMultiCheck(
                $event,
                this.recordDays,
                'disponibilite'
              )
            "
          />
        </div>
        <div class="editparameters__recorddays__day">
          <label
            class="form-check-label d-block"
            for="create-speaker-record-sat"
            >Samedi</label
          >
          <input
            class="form-check-input"
            type="checkbox"
            name="create-speaker-record-sat"
            id="create-speaker-record-sat"
            value="Sat"
            formControlName="__joursenregistrementSat"
            (change)="
              this.updatePristineStatus();
              this.saveMultiCheck(
                $event,
                this.recordDays,
                'disponibilite'
              )
            "
          />
        </div>
        <div class="editparameters__recorddays__day">
          <label
            class="form-check-label d-block"
            for="create-speaker-record-sun"
            >Dimanche</label
          >
          <input
            class="form-check-input"
            type="checkbox"
            name="create-speaker-record-sun"
            id="create-speaker-record-sun"
            value="Sun"
            formControlName="__joursenregistrementSun"
            (change)="
              this.updatePristineStatus();
              this.saveMultiCheck(
                $event,
                this.recordDays,
                'disponibilite'
              )
            "
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <h6>Limitations</h6>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col-6">
      <div class="form-floating">
        <input
          class="form-control"
          type="number"
          name="create-speaker-textslimit"
          id="create-speaker-textslimit"
          formControlName="nbtxtpv"
          placeholder=""
          (change)="this.updatePristineStatus()"
        />
        <label for="create-speaker-textslimit"
          >Nombre de textes par séance</label
        >
      </div>
    </div>
    <div class="col-6">
      <div class="form-floating">
        <input
          class="form-control"
          type="number"
          name="create-speaker-seanceslimit"
          id="create-speaker-seanceslimit"
          formControlName="nbpvjour"
          placeholder=""
          (change)="this.updatePristineStatus()"
        />
        <label for="create-speaker-seanceslimit"
          >Nombre de séances par session</label
        >
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <h6>Traduction</h6>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-12 d-flex ">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          name="create-speaker-traductrice"
          id="create-speaker-traductrice"
          formControlName="traductrice"
        />
        <label class="form-check-label" for="create-speaker-traductrice">Traducteur.trice ?</label>
      </div>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-12">
      <h6>Mode.s de facturation</h6>
    </div>
    <div class="col-12">
      <button type="button" class="mt-3 mb-3 col-5 btn btn-primary" (click)="showRowAddModeFactu = true"><i class="fa-solid fa-file-invoice-dollar ms-2"></i> Ajouter une facturation ? </button>
      <div class="row mt-3" *ngIf="showRowAddModeFactu">
        <div class="col-4">
          <div class="form-floating">
            <select class="form-select" id="formSelectZcg" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="selectedTypeProd" >
              <option value="0"></option>
              <option *ngFor="let typeProd of zcg" [value]="typeProd.cc_code">{{ typeProd.cc_libelle }}</option>
            </select>
            <label for="formSelectZcg">Type de production</label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-floating">
            <select class="form-select" id="formSelectModeFact" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="selectedTypeFactu">
              <option value=0></option>
              <option *ngFor="let typeFact of modeFactuZapp" [value]="typeFact.id">{{ typeFact.libelle }}</option>
            </select>
            <label for="formSelectModeFact">Type de facturation</label>
          </div>
        </div>
        <div class="col-1"></div>
        <div class="col-3">
          <button type="button" class="btn btn-primary" (click)="addNewModeFactu()">Ajouter</button>
        </div>
      </div>
      <table class="mt-4 table table-striped " *ngIf="modesFacturation?.length !== 0">
        <thead>
        <tr>
          <th>Type production</th>
          <th>Choix mode</th>
          <th>Supprimer</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let mode of modesFacturation">
          <td>{{displayActualMode(mode)}}</td>
          <td>{{mode.mode_facturation.libelle}}</td>
          <td><button type="button" class=" ms-2 btn btn-danger" (click)="deleteModeFactu(mode.id)">
            <i class="fa-solid fa-trash"></i></button></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <h6>Mode On Air</h6>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col-8 d-flex align-items-center justify-content-between">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          name="create-speaker-onair"
          id="create-speaker-onair"
          formControlName="onair"
          (change)="this.updatePristineStatus()"
        />
        <label class="form-check-label" for="create-speaker-onair"
          >Activé</label
        >
      </div>
      <div class="form-floating">
        <input
          class="form-control"
          name="create-speaker-nbonairjour"
          id="create-speaker-nbonairjour"
          type="number"
          formControlName="nbonairjour"
          (change)="this.updatePristineStatus()"
        />
        <label for="create-speaker-nbonairjour">Nb séances max. par jour</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div *ngIf="!this.editParameters.pristine" class="col-12 mb-2 text-end">
      <button class="btn btn-danger me-2" (click)="this.resetForm()">
        Annuler les modifications
      </button>
      <button
        class="btn btn-success"
        (click)="this.saveParameters()"
        [disabled]="this.isLoading"
      >
        <span *ngIf="!this.isLoading"> Enregistrer </span>
        <span *ngIf="this.isLoading" class="d-flex">
          Modification en cours
          <div class="ms-3">
            <app-loader color="#fff" />
          </div>
        </span>
      </button>
    </div>
  </div>

  <div class="editparameters__dangerzone">
    <h6 class="mb-0">Danger zone</h6>
    <div class="editparameters__dangerzone__body">
      <button class="btn btn-danger me-3" (click)="this.disableSpeaker()">
        Désactiver le profil
      </button>
      <button class="btn btn-warning">
        Demander un changement de mot de passe
      </button>
    </div>
  </div>
</div>

